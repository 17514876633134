import { Row, Col, Form, Input, InputNumber, Select, DatePicker } from "antd";
import get from "lodash/get";
import { ownershipOptions } from "../../../../utilities/vehicle";

const VehicleBasicInfo = ({ vehicleConfig, form }) => {
  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const validateRegistrationExpiration = (rule, value) => {
    const registrationDate = form.getFieldValue("date_of_registration");
    if (value && registrationDate && value.isBefore(registrationDate)) {
      return Promise.reject(
        "Expiration date cannot be before the registration date"
      );
    }
    return Promise.resolve();
  };

  const validateMakingYear = (rule, value) => {
    const registrationDate = form.getFieldValue("date_of_registration");
    if (value && registrationDate && value.isAfter(registrationDate)) {
      return Promise.reject(
        "Making Year cannot be before the registration date"
      );
    }
    return Promise.resolve();
  };

  return (
    <>
      <Row gutter={32}>
        <Col span={8}>
          <Form.Item
            label="Brand"
            name={"brand_id"}
            rules={[{ required: true, message: "Please select brand" }]}
          >
            <Select
              showSearch
              filterOption={filterOption}
              size="large"
              onChange={() => {}}
              placeholder="Select Brand"
              options={get(vehicleConfig, "brands")}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label="Name"
            name={"name"}
            rules={[{ required: true, message: "Please enter name" }]}
          >
            <Input size="large" placeholder="Name" />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            label="Model"
            name={"model"}
            rules={[{ required: true, message: "Please enter modal" }]}
          >
            <Input size="large" placeholder="Modal" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={32}>
        <Col span={8}>
          <Form.Item
            label="Fuel Type"
            name={"fuel_type"}
            rules={[{ required: true, message: "Please select fuel type" }]}
          >
            <Select
              showSearch
              filterOption={filterOption}
              size="large"
              onChange={() => {}}
              placeholder="Select Fuel Type"
              options={get(vehicleConfig, "fuel_type")}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label="Body Type"
            name={"body_type"}
            rules={[{ required: true, message: "Please select body type" }]}
          >
            <Select
              showSearch
              filterOption={filterOption}
              size="large"
              onChange={() => {}}
              placeholder="Select Body Type"
              options={get(vehicleConfig, "body_type")}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label="Color"
            name={"color"}
            rules={[{ required: true, message: "Please enter color" }]}
          >
            <Input size="large" placeholder="Color" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={32}>
        <Col span={8}>
          <Form.Item
            label="Km Travelled"
            name={"km_used"}
            rules={[{ required: true, message: "Please enter Km travelled " }]}
          >
            <InputNumber
              className="flex w-full"
              size="large"
              placeholder="Km Travelled"
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label="Ownership"
            name={"ownership"}
            rules={[{ required: true, message: "Please select ownership" }]}
          >
            <Select
              showSearch
              filterOption={filterOption}
              size="large"
              onChange={() => {}}
              placeholder="Ownership"
              options={ownershipOptions}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label="Making Year"
            name={"make_year"}
            rules={[
              { required: true, message: "Please select making year" },
              { validator: validateMakingYear },
            ]}
          >
            <DatePicker
              className="flex w-full"
              size="large"
              onChange={() => {}}
              picker="year"
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={32}>
        <Col span={8}>
          <Form.Item
            label="Registration Date"
            name={"date_of_registration"}
            rules={[
              { required: true, message: "Please enter registration date" },
            ]}
          >
            <DatePicker
              className="flex w-full"
              format="DD-MM-YYYY"
              size="large"
              onChange={() => {}}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label="Registration Expiry Date"
            name={"date_registration_exp"}
            rules={[
              {
                required: true,
                message: "Please select registration expiry date",
              },
              { validator: validateRegistrationExpiration },
            ]}
          >
            <DatePicker
              className="flex w-full"
              format="DD-MM-YYYY"
              size="large"
              onChange={() => {}}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={32}>
        <Col span={8}>
          <Form.Item
            label="RTO State"
            name={"rto_state"}
            rules={[{ required: true, message: "Please select RTO State" }]}
          >
            <Select
              showSearch
              filterOption={filterOption}
              size="large"
              onChange={() => {}}
              placeholder="RTO State"
              options={get(vehicleConfig, "rto_state")}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label={
              <p>
                Price <span className="text-lightGrey">(In Rupee)</span>
              </p>
            }
            name={"price"}
            rules={[{ required: true, message: "Please enter price" }]}
          >
            <InputNumber
              className="flex w-full"
              size="large"
              placeholder="Price"
            />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};

export default VehicleBasicInfo;
