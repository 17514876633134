import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button, Input, Form, Spin, message } from "antd";
import { forgotPassword } from "../../../redux/actions/authActions";
import { EMAIL_SEND_SUCCESS } from "../../../constants/message";
import { LOGIN } from "../../../constants/routes";

const ForgotPasswordForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const onFinish = (values) => {
    setLoading(true);
    dispatch(forgotPassword(values))
      .then((result) => {
        setLoading(false);
        message.success(EMAIL_SEND_SUCCESS);
        navigate(LOGIN)
      })
      .catch(() => {
        setLoading(false);
      });
  };

  return (
    <Spin spinning={loading}>
      <div className="flex flex-col justify-start">
        <Form
          name="login"
          layout="vertical"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={() => {}}
        >
          <Form.Item
            name="email"
            label="Email"
            rules={[
              { required: true, message: "Please input your Email!" },
              {
                type: "email",
                message: "Please input valid email",
              },
            ]}
          >
            <Input size="large" placeholder="Email" />
          </Form.Item>

          <Button
            htmlType="submit"
            type="primary"
            className="w-full mt-3"
            style={{ height: 40 }}
            // onClick={()=>onLogin()}
          >
            Submit
          </Button>
        </Form>
        <div
          className="flex flex-col w-full justify-center items-center py-5"
          onClick={() => navigate(LOGIN)}
        >
          <p className="text-sm text-primary hover:cursor-pointer underline">
            Login
          </p>
        </div>
      </div>
    </Spin>
  );
};

export default ForgotPasswordForm;
