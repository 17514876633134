import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Card,
  Form,
  Input,
  message,
  Spin,
  Image,
  Alert,
  Row,
} from "antd";
import { useNavigate } from "react-router-dom";
import {
  verifyforgotPassword,
  changeforgotPassword,
} from "../../redux/actions/authActions";
import logoIcon from "../../static/images/logo.png";
import { useParams } from "react-router-dom";
import { CHANGE_PASSWORD_SUCCESS } from "../../constants/message";
import { LOGIN } from "../../constants/routes";
import { LOGOUT } from "../../constants/actionTypes";
import get from "lodash/get";

const ChangePasswordScreen = () => {
  const { id } = useParams();
  const isLoggedIn  = useSelector(state=>get(state,'auth.isLoggedIn'))
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [linkVerified, setLinkVerfied] = useState({
    status: false,
    apicalled: false,
  });

  const onFinish = (values) => {
    const payload = {
      ...values,
      link_uuid: id,
    };
    setLoading(true);
    dispatch(changeforgotPassword(payload))
      .then(() => {
        setLoading(false);
        message.success(CHANGE_PASSWORD_SUCCESS);
        navigate(LOGIN);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const onFinishFailed = () => {};

  const verifyPasswordLink = () => {
    setLoading(true);
    dispatch(verifyforgotPassword({ link_uuid: id }))
      .then(() => {
        setLoading(false);
        setLinkVerfied({
          status: true,
          apicalled: true,
        });
      })
      .catch(() => {
        setLoading(false);
        setLinkVerfied({
          status: false,
          apicalled: true,
        });
      });
  };

  useEffect(() => {
    if(isLoggedIn){
      dispatch({
        type: LOGOUT,
      });
    }
    verifyPasswordLink();
  }, []);

  return (
    <Spin spinning={loading}>
      <Row className="flex flex-col  h-screen mt-20 items-center">
        {get(linkVerified, "apicalled") && !get(linkVerified, "status") ? (
          <Alert
            showIcon
            message={
              <p className="text-black text-lg font-medium text-start">
                Invalid Link
              </p>
            }
            description="The link is incorrect or expired. Please request a new one or contact support"
            type="error"
          />
        ) : (
          <Card style={{ width: 450 }}>
            <div className="flex flex-col space-y-1 pb-8 justify-center items-center">
              <Image src={logoIcon} height={40} width={190} />
              <p className="text-black text-xl font-semibold pt-5">
                Change Password
              </p>
              <p className="text-sm text-black opacity-45">
                Enter a new password below to change your password
              </p>
            </div>
            <Form
              className="form-input"
              form={form}
              layout="vertical"
              scrollToFirstError
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
            >
              <Form.Item
                label="Password"
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Please enter password!",
                  },
                ]}
              >
                <Input.Password size="large" placeholder="Password" />
              </Form.Item>
              <Form.Item
                label="Confirm Password"
                name="confirm_password"
                rules={[
                  {
                    required: true,
                    message: "Please confirm your password!",
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error(
                          "The two passwords that you entered do not match!"
                        )
                      );
                    },
                  }),
                ]}
              >
                <Input.Password size="large" placeholder="Confirm Password" />
              </Form.Item>
              <Form.Item className="login-form-item pt-10">
                <Button
                  block
                  type="primary"
                  htmlType="submit"
                  style={{ height: 44 }}
                >
                  Update Password
                </Button>
              </Form.Item>
            </Form>
          </Card>
        )}
      </Row>
    </Spin>
  );
};
export default ChangePasswordScreen;
