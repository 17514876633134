import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  FacebookFilled,
  InstagramFilled,
  YoutubeFilled,
  TwitterOutlined,
} from "@ant-design/icons";
import requireAuth from "../../hocs/requireAuth";
import AddVendorForm from "../../components/forms/AddVendorForm";
import {
  Button,
  Drawer,
  Image,
  Spin,
  Descriptions,
  Divider,
  Row,
  Col,
  Space,
  Modal,
} from "antd";
import get from "lodash/get";
import assign from "lodash/assign";
import isNull from "lodash/isNull";
import map from "lodash/map";

import { fetchVendorDetails } from "../../redux/actions/vendorActions";
import {
  getVendorDescription,
  getWebsiteDescription,
} from "../../utilities/vendor";
import { COLOR_PRIMARY } from "../../constants";
import VendorSubscription from "../../components/vendorSubscription";

const VendorSettingScreen = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [vendorDetails, setVendorDetails] = useState({});
  const [drawer, setDrawer] = useState({
    visible: false,
    edit: true,
    data: null,
  });

  const toggleVendorDrawer = (data = null) => {
    assign(drawer, {
      data: data,
      visible: !get(drawer, "visible"),
      edit: !isNull(data),
    });
    setDrawer({ ...drawer });
  };

  const [subscriptionModal, setSubscriptionModal] = useState({
    visible: false,
    data: null,
  });

  const toggleSubscriptionModal = (data = null) => {
    assign(subscriptionModal, {
      visible: !get(subscriptionModal, "visible"),
      data,
    });
    setSubscriptionModal({ ...subscriptionModal });
  };

  const getVendorDetails = () => {
    setLoading(true);
    dispatch(fetchVendorDetails())
      .then((result) => {
        setVendorDetails(result);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getVendorDetails();
  }, []);

  return (
    <>
      {loading && <Spin spinning={loading} className="spinner-overlay"></Spin>}
      <>
        <div className="p-3 flex flex-col h-full">
          <div className="flex flex-row justify-between pb-8 ">
            <div>
              <p className="text-xl font-medium leading-[30px] text-black-1">
                Vendor Settings
              </p>
            </div>
            <div>
              <Space>
                <Button
                  type="primary"
                  onClick={() => toggleSubscriptionModal(vendorDetails)}
                >
                  View Subscription
                </Button>
                <Button
                  type="primary"
                  onClick={() => toggleVendorDrawer(vendorDetails)}
                >
                  Edit
                </Button>
              </Space>
            </div>
          </div>
          <div className="p-8 bg-white h-full rounded-lg flex flex-row space-x-28 w-full overflow-y-auto scrollbar-hidden min-w-full">
            <Row gutter={32}>
              <Col span={11}>
                <Descriptions
                  title=""
                  column={1}
                  items={getVendorDescription(vendorDetails)}
                />
                <Divider>Website Details</Divider>
                <Descriptions
                  title=""
                  column={1}
                  items={getWebsiteDescription(vendorDetails)}
                />
                <div className="items-start flex">
                  <Space size={20}>
                    {get(vendorDetails, "website_details.facebook_url") && (
                      <FacebookFilled
                        style={{ fontSize: 30, color: COLOR_PRIMARY }}
                        onClick={() =>
                          window.open(
                            get(vendorDetails, "website_details.facebook_url"),
                            "_blank"
                          )
                        }
                        twoToneColor={COLOR_PRIMARY}
                        className="social-media-icons"
                      />
                    )}
                    {get(vendorDetails, "website_details.youtube_url") && (
                      <YoutubeFilled
                        style={{ fontSize: 30, color: COLOR_PRIMARY }}
                        onClick={() =>
                          window.open(
                            get(vendorDetails, "website_details.youtube_url"),
                            "_blank"
                          )
                        }
                        twoToneColor={COLOR_PRIMARY}
                        className="social-media-icons"
                      />
                    )}
                    {get(vendorDetails, "website_details.instagram_url") && (
                      <InstagramFilled
                        style={{ fontSize: 30, color: COLOR_PRIMARY }}
                        onClick={() =>
                          window.open(
                            get(vendorDetails, "website_details.instagram_url"),
                            "_blank"
                          )
                        }
                        twoToneColor={COLOR_PRIMARY}
                        className="social-media-icons"
                      />
                    )}
                    {get(vendorDetails, "website_details.twitter_url") && (
                      <TwitterOutlined
                        style={{ fontSize: 30, color: COLOR_PRIMARY }}
                        onClick={() =>
                          window.open(
                            get(vendorDetails, "website_details.instagram_url"),
                            "_blank"
                          )
                        }
                        twoToneColor={COLOR_PRIMARY}
                        className="social-media-icons"
                      />
                    )}
                  </Space>
                </div>
              </Col>
              <Col span={2} />
              <Col span={11}>
                <div className=" basis-1/2 space-y-5 h-full">
                  <div className="flex flex-col items-start space-y-2 ">
                    <div className="flex flex-col items-start space-y-1">
                      <p className="text-black  opacity-45 pb-5 ">Logo</p>
                      <div className="h-[60px] min-w-52 relative flex flex-col overflow-hidden">
                        <Image
                          src={get(vendorDetails, "logo.url")}
                          className="absolute flex object-cover "
                          width={"100%"}
                          height={"100%"}
                        />
                      </div>
                    </div>
                  </div>
                  <div className=" space-y-5">
                    <p className="text-black  opacity-45">Banner Images</p>
                    <div key="image" className="flex gap-5 h-full flex-wrap w-full ">
                      {map(
                        get(vendorDetails, "banner_images"),
                        (bannerImage) => (
                          <div className="h-[90px] min-w-40 relative  flex rounded-lg overflow-hidden">
                            <Image
                              src={get(bannerImage, "url")}
                              className="absolute flex object-cover "
                              width={"100%"}
                              height={"100%"}
                            />
                          </div>
                        )
                      )}
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </>
      {get(drawer, "visible") && (
        <Drawer
          title={`Edit Vendor`}
          destroyOnClose
          width={"50%"}
          onClose={() =>
            setDrawer({
              visible: false,
              edit: false,
              data: null,
            })
          }
          open={get(drawer, "visible")}
        >
          <AddVendorForm
            onSuccess={() => {
              getVendorDetails();
              toggleVendorDrawer();
            }}
            edit={get(drawer, "edit")}
            initialValues={get(drawer, "data")}
            toggleVendorDrawer={toggleVendorDrawer}
          />
        </Drawer>
      )}
      {get(subscriptionModal, "visible") && (
        <Modal
          title={"Subscription"}
          open={get(subscriptionModal, "visible")}
          destroyOnClose
          width={"60%"}
          footer={false}
          onCancel={toggleSubscriptionModal}
          bodyStyle={{
            height: "80vh",
          }}
          style={{ marginTop: 0, top: 40 }}
        >
          <VendorSubscription vendor={vendorDetails} />
        </Modal>
      )}
    </>
  );
};

export default requireAuth(VendorSettingScreen, [2]);
