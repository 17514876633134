import { Row, Form, Checkbox, List, Col } from "antd";
import get from "lodash/get";

const InspectionChecks = ({ vehicleConfig }) => {
  return (
    <Row>
      <Col span={24} className="overflow-hidden">
        <Form.Item
          label=""
          name={"inspection_checks"}
          rules={[
            { required: true, message: "Please select inspection checks" },
          ]}
        >
          <Checkbox.Group className="flex flex-col space-y-3">
            <List
              dataSource={get(vehicleConfig, "inspections", [])}
              renderItem={(item) => (
                <List.Item>
                  <Checkbox value={item.value} className="custom-checkbox">
                    {item.label}
                  </Checkbox>
                </List.Item>
              )}
              className=" max-h-[495px] 2xl:max-h-[640px] overflow-y-auto scrollbar-hidden"
            />
          </Checkbox.Group>
        </Form.Item>
      </Col>
    </Row>
  );
};

export default InspectionChecks;
