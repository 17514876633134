import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { ExclamationCircleFilled } from "@ant-design/icons";
import {
  Button,
  Table,
  Spin,
  Modal,
  Tag,
  Space,
  Input,
  message,
  Drawer,
} from "antd";
import get from "lodash/get";
import assign from "lodash/assign";
import isNull from "lodash/isNull";
import map from "lodash/map";
import filter from "lodash/filter";
import includes from "lodash/includes";
import {
  fetchBrodcastGroups,
  deleteBrodcastGroup,
} from "../../redux/actions/brodcastGroupActions";
import { BrodcastGroupColumns } from "../../constants/tableColumns/brodcastGroup";
import AddBrodcastGroup from "../../components/forms/AddBrodcastGroup";
import { DELETE_GROUP_SUCCESS } from "../../constants/message";

const { confirm } = Modal;

const BroadcastGroupScreen = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [brodcastGroups, setBropdcastGroups] = useState({
    data: [],
  });

  const [brodcastGroupForm, setBrodcastGroupForm] = useState({
    visible: false,
    edit: false,
    data: null,
  });

  const [enteriesModal, setEnteriesModal] = useState({
    visible: false,
    data: [],
    filteredData: [],
  });

  const toggleEnteriesModal = (data) => {
    assign(enteriesModal, {
      visible: !get(enteriesModal, "visible"),
      data,
      filteredData: data,
    });
    setEnteriesModal({ ...enteriesModal });
  };

  const toggleBrodcastGroupForm = (data = null) => {
    assign(brodcastGroupForm, {
      visible: !get(brodcastGroupForm, "visible"),
      edit: !isNull(data),
      data,
    });
    setBrodcastGroupForm({ ...brodcastGroupForm });
  };

  const getBropdcastGroups = () => {
    setLoading(true);
    dispatch(fetchBrodcastGroups())
      .then((result) => {
        setLoading(false);
        setBropdcastGroups({
          data: result,
        });
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const deleteItem = (id) => {
    setLoading(true);
    dispatch(deleteBrodcastGroup(id))
      .then(() => {
        message.success(DELETE_GROUP_SUCCESS);
        getBropdcastGroups();
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const onClickDelete = (record) => {
    confirm({
      title: `Are you sure you want to delete ${get(record, "name")} group?`,
      icon: <ExclamationCircleFilled />,
      content: "Once item is deleted you can't recover this !",
      onOk() {
        deleteItem(get(record, "id"));
      },
      onCancel() {},
    });
  };

  useEffect(() => {
    getBropdcastGroups();
  }, []);

  return (
    <>
      <div className="p-3 h-full flex flex-col">
        <div className="flex flex-row justify-between pb-8">
          <div>
            <p className="text-xl font-medium leading-[30px] text-black-1">
              Brodcast Groups
            </p>
          </div>
          <div>
            <Space>
              <Button type="primary" onClick={() => toggleBrodcastGroupForm()}>
                Add Brodcast Group
              </Button>
            </Space>
          </div>
        </div>

        <div className="h-full rounded-xl overflow-hidden bg-white flex-1 overflow-y-auto scrollbar-hidden pb-5 min-w-full">
          <div className="w-full p-5 ">
            <div className="justify-end flex items-center h-full "></div>
          </div>
          <div className="px-8">
            <Spin spinning={loading}>
              <Table
                pagination={false}
                columns={BrodcastGroupColumns({
                  toggleBrodcastGroupForm,
                  toggleEnteriesModal,
                  onClickDelete,
                })}
                dataSource={get(brodcastGroups, "data")}
              />
            </Spin>
          </div>
        </div>
      </div>
      {get(brodcastGroupForm, "visible") && (
        <Drawer
          title={
            get(brodcastGroupForm, "edit")
              ? `Update Brodcast Group`
              : "Add Brodcast Group"
          }
          open={get(brodcastGroupForm, "visible")}
          destroyOnClose
          width="80%"
          footer={false}
          onClose={() => toggleBrodcastGroupForm()}
        >
          <AddBrodcastGroup
            toggleBrodcastGroupForm={toggleBrodcastGroupForm}
            onSuccess={() => getBropdcastGroups()}
            edit={get(brodcastGroupForm, "edit")}
            initialValues={get(brodcastGroupForm, "data")}
          />
        </Drawer>
      )}
      {get(enteriesModal, "visible") && (
        <Modal
          title={"Enteries"}
          open={get(enteriesModal, "visible")}
          destroyOnClose
          width="80%"
          footer={false}
          onCancel={() => toggleEnteriesModal()}
        >
          <div>
            <div className="py-5">
              <Input
                placeholder="Search Enteries"
                allowClear
                enterButton="Search"
                size="large"
                onChange={(val) => {
                  assign(enteriesModal, {
                    filteredData: filter(get(enteriesModal, "data"), (item) =>
                      includes(item, val.target.value)
                    ),
                  });
                  setEnteriesModal({ ...enteriesModal });
                }}
              />
            </div>
            {map(get(enteriesModal, "filteredData"), (entry) => (
              <Tag className="m-1" color="geekblue">
                {entry}
              </Tag>
            ))}
          </div>
        </Modal>
      )}
    </>
  );
};

export default BroadcastGroupScreen;
