import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import get from "lodash/get";
import assign from "lodash/assign";
import { Spin, Table, Drawer, Tag, DatePicker, Row, Col, Select } from "antd";
import { fetchLeads } from "../../redux/actions/LeadsActions";
import { LeadsColumns } from "../../constants/tableColumns/leadsColumns";
import LeadsDetails from "../../components/leadsDetails";
import { getLeadStatusBadgeColor } from "../../utilities/leads";
import { getLeadStatus } from "../../utilities/leads";
import dayjs from "dayjs";

const { RangePicker } = DatePicker;

const LeadsScreen = () => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [leads, setLeads] = useState({
    data: [],
    pagination: {},
    filters: { limit: 10 },
  });

  const [leadDrawer, setLeadDrawer] = useState({
    visible: false,
    data: null,
  });

  const toggleLeadDrawer = (data = null) => {
    assign(leadDrawer, {
      visible: !get(leadDrawer, "visible"),
      data,
    });
    setLeadDrawer({ ...leadDrawer });
  };

  const getLeads = (page = 1, filters) => {
    const params = { page, ...get(leads, "filters"), ...filters };
    setLoading(true);
    dispatch(fetchLeads(params))
      .then((result) => {
        setLoading(false);
        assign(leads, {
          data: get(result, "data"),
          pagination: get(result, "meta.pagination"),
          filters: params,
        });
        setLeads({
          ...leads,
        });
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getLeads();
  }, []);

  return (
    <>
      <div className="p-3 h-full flex flex-col">
        <div className="flex flex-row justify-between pb-8">
          <div>
            <p className="text-xl font-medium leading-[30px] text-black-1">
              Leads
            </p>
          </div>
        </div>

        <div className="h-full rounded-xl overflow-hidden bg-white flex-1 overflow-y-auto scrollbar-hidden pb-5 min-w-full">
          <div className="w-full px-8 py-6 ">
            <div className="justify-end flex items-center h-full ">
              <Row gutter={32}>
                <Col>
                  <Select
                    mode="multiple"
                    size="large"
                    style={{ minWidth: 170 }}
                    placeholder="Status"
                    options={getLeadStatus}
                    onChange={(val) => {
                      getLeads(1, { status: val });
                    }}
                  />
                </Col>
                <Col>
                  <RangePicker
                    size="large"
                    onChange={(date) => {
                      if (date) {
                        getLeads(1, {
                          start_date: dayjs(get(date, "0")).format(
                            "YYYY-MM-DD"
                          ),
                          end_date: dayjs(get(date, "1")).format("YYYY-MM-DD"),
                        });
                        return;
                      }
                      getLeads(1, { start_date: null, end_date: null });
                    }}
                  />
                </Col>
              </Row>
            </div>
          </div>
          <div className="px-8">
            <Spin spinning={loading}>
              <Table
                columns={LeadsColumns({
                  toggleLeadDrawer,
                })}
                dataSource={get(leads, "data")}
                pagination={{
                  pageSize: get(leads, "pagination.per_page", 1),
                  current: get(leads, "pagination.current_page", 1),
                  total: get(leads, "pagination.total", 1),
                  onChange: (val) => {
                    getLeads(val);
                  },
                }}
              />
            </Spin>
          </div>
        </div>
        <Drawer
          title={
            <div className="flex flex-row space-x-5">
              <p className="text-black">Lead</p>
              <Tag
                color={getLeadStatusBadgeColor(
                  get(leadDrawer, "data.status.value")
                )}
              >
                {get(leadDrawer, "data.status.name")}
              </Tag>
            </div>
          }
          destroyOnClose
          width={"90%"}
          onClose={() => {
            toggleLeadDrawer();
            getLeads();
          }}
          open={get(leadDrawer, "visible")}
        >
          <LeadsDetails initialLead={get(leadDrawer, "data")} />
        </Drawer>
      </div>
    </>
  );
};

export default LeadsScreen;
