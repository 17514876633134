import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Spin, Table, Button, Modal, message } from "antd";
import { ExclamationCircleFilled } from "@ant-design/icons";
import get from "lodash/get";
import isNull from "lodash/isNull";
import assign from "lodash/assign";
import { SubscriptionColumns } from "../../constants/tableColumns/subscriptionColumns";
import {
  fetchSubscription,
  deleteSubscription,
} from "../../redux/actions/subscriptionActions";
import AddSubscription from "../../components/forms/AddSubscription";
import { DELETE_SUBSCRIPTION_SUCCESS } from "../../constants/message";
import requireAuth from "../../hocs/requireAuth";

const { confirm } = Modal;

const SubscriptionScreen = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [subscriptions, setSubsCriptions] = useState({
    data: [],
  });

  const [subscriptionModal, setSubscriptionModal] = useState({
    data: null,
    visible: false,
    edit: false,
  });

  const toggleSubscriptionModal = (data = null) => {
    assign(subscriptionModal, {
      visible: !get(subscriptionModal, "visible"),
      data,
      edit: !isNull(data),
    });
    setSubscriptionModal({ ...subscriptionModal });
  };

  const getSubscription = () => {
    setLoading(true);
    dispatch(fetchSubscription())
      .then((result) => {
        setLoading(false);
        setSubsCriptions({
          data: result,
        });
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const onDeleteSubscription = (id) => {
    setLoading(true);
    dispatch(deleteSubscription(id))
      .then(() => {
        message.success(DELETE_SUBSCRIPTION_SUCCESS);
        getSubscription();
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const onClickDelete = (record) => {
    confirm({
      title: `Are you sure you want to delete ${get(
        record,
        "name"
      )} subscription?`,
      icon: <ExclamationCircleFilled />,
      content: "Once item is deleted you can't recover this !",
      onOk() {
        onDeleteSubscription(get(record, "id"));
      },
      onCancel() {},
    });
  };

  useEffect(() => {
    getSubscription();
  }, []);

  return (
    <>
      <div className="p-5 h-full flex flex-col">
        <div className="flex flex-row justify-between pb-8">
          <div>
            <p className="text-xl font-medium leading-[30px] text-black-1">
              Subscriptions
            </p>
          </div>
          <div>
            <Button
              type="primary"
              onClick={() => {
                toggleSubscriptionModal();
              }}
            >
              Add Subscription
            </Button>
          </div>
        </div>

        <div className="h-full rounded-xl overflow-hidden bg-white flex-1 overflow-y-auto scrollbar-hidden pb-5 min-w-full">
          <div className="w-full p-5 ">
            <div className="justify-end flex items-center h-full "></div>
          </div>
          <div className="px-8">
            <Spin spinning={loading}>
              <Table
                pagination={false}
                columns={SubscriptionColumns({
                  toggleSubscriptionModal,
                  onClickDelete,
                })}
                dataSource={get(subscriptions, "data")}
              />
            </Spin>
          </div>
        </div>
      </div>
      {get(subscriptionModal, "visible") && (
        <Modal
          destroyOnClose
          footer={null}
          open={get(subscriptionModal, "visible")}
          scrollToFirstError
          width={"40%"}
          title={
            <p className="text-black text-lg">
              {get(subscriptionModal, "edit")
                ? "Update Subscription"
                : "Add Subscription"}
            </p>
          }
          onCancel={toggleSubscriptionModal}
        >
          <AddSubscription
            edit={get(subscriptionModal, "edit")}
            initialValues={get(subscriptionModal, "data")}
            toggleSubscriptionModal={toggleSubscriptionModal}
            onSuccess={() => getSubscription()}
          />
        </Modal>
      )}
    </>
  );
};

export default requireAuth(SubscriptionScreen,[1]);
