import { Spin, Form, Select, Button, message, Modal } from "antd";
import { useState } from "react";
import { useDispatch } from "react-redux";
import get from "lodash/get";
import map from "lodash/map";
import find from "lodash/find";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { groupTypeOptions } from "../../../utilities/brodcastGroup";
import {
  fetchBrodcastGroups,
  generateBrodcast,
} from "../../../redux/actions/brodcastGroupActions";
import { BRODCAST_MESSAGE_SUCCESS } from "../../../constants/message";

const BrodcastForm = ({ toggleBrodcastModal, vehicle }) => {
  const [modal, contextHolder] = Modal.useModal();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [brodcastGroups, setBropdcastGroups] = useState([]);
  const [loading, setLoading] = useState(false);

  const onClickSend = (groupId) => {
    const selectedGroup = find(
      brodcastGroups,
      (brodcastGroup) => get(brodcastGroup, "id") === groupId
    );
    modal.confirm({
      title: "Are you sure you want to generate Brodcast?",
      icon: <ExclamationCircleOutlined />,
      content: `This action will debit ${
        get(selectedGroup, "enteries", []).length
      } credits from your total ${get(selectedGroup, "type.name")} credits.`,
      okText: "Yes",
      cancelText: "No",
      onOk: () => onGenerateBrodcast(groupId),
    });
  };

  const getBropdcastGroups = (params) => {
    setLoading(true);
    dispatch(fetchBrodcastGroups(params))
      .then((result) => {
        setLoading(false);
        setBropdcastGroups(
          map(result, (group) => ({
            ...group,
            label: get(group, "name"),
            value: get(group, "id"),
          }))
        );
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const onGenerateBrodcast = (groupId) => {
    setLoading(true);
    dispatch(generateBrodcast(get(vehicle, "id"), groupId))
      .then(() => {
        setLoading(false);
        toggleBrodcastModal();
        message.success(BRODCAST_MESSAGE_SUCCESS);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const onFinish = (values) => {
    onClickSend(get(values, "group"));
  };

  return (
    <Spin spinning={loading}>
      {contextHolder}
      <div className="flex flex-col justify-start">
        <Form
          form={form}
          name="login"
          layout="vertical"
          onFinish={onFinish}
          onFinishFailed={() => {}}
        >
          <Form.Item
            name="type"
            label="Type"
            rules={[{ required: true, message: "Please select type" }]}
          >
            <Select
              size="large"
              placeholder="Type"
              options={groupTypeOptions}
              onChange={(val) => {
                getBropdcastGroups({ type: String(val) });
                form.setFieldsValue({
                  ...form.getFieldsValue(),
                  group: undefined,
                });
              }}
            />
          </Form.Item>
          <Form.Item
            name="group"
            label="Group"
            rules={[{ required: true, message: "Please select group!" }]}
          >
            <Select size="large" placeholder="Group" options={brodcastGroups} />
          </Form.Item>
          <div className="flex flex-row justify-end space-x-5">
            <Button onClick={() => toggleBrodcastModal()}>Cancel</Button>
            <Button type="primary" htmlType="submit">
              Send
            </Button>
          </div>
        </Form>
      </div>
    </Spin>
  );
};

export default BrodcastForm;
