import get from "lodash/get";

export const getLeadStatusBadgeColor = (status) => {
  switch (status) {
    case 0:
      return "cyan";
    case 1:
      return "green";
    case 2:
      return "purple";
    case 3:
      return "volcano";
    default:
      return "red";
  }
};

export const getLeadStatus = [
  { label: "New", value: 0 },
  { label: "Contacted", value: 1 },
  { label: "Follow-up", value: 2 },
  { label: "Closed", value: 3 },
  { label: "Test Drive", value: 4 },
];

export const getDashboardLeads=(leads) => [
  { label: "New", value: get(leads,'0.total'), color : "#5570F1" },
  { label: "Contacted", value: get(leads,'1.total'), color : "#97A5EB" },
  { label: "Follow Ups", value: get(leads,'2.total'), color : "#FFCC91" },
  { label: "Closed", value: get(leads,'3.total'), color : "#FF91AB" },
]

export const leadsDescription = (lead) => [
  {
    key: "1",
    label: "Name",
    children: get(lead, "name"),
  },
  {
    key: "2",
    label: "Phone",
    children: get(lead, "phone"),
  },
  {
    key: "4",
    label: "Email",
    children: get(lead, "email"),
  },
];

export const leadsVehicleDescription = (vehicle) => [
  {
    key: "1",
    label: "Name",
    children: get(vehicle, "name"),
  },
  {
    key: "2",
    label: "Brand",
    children: get(vehicle, "brand.name"),
  },
  {
    key: "3",
    label: "Price",
    children: get(vehicle, "price"),
  },
  {
    key: "3",
    label: "Fuel Type",
    children: get(vehicle, "fuel_type.name"),
  },
  {
    key: "3",
    label: "RTO State",
    children: get(vehicle, "rto_state.name"),
  },
];
