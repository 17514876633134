import { useState } from "react";
import { useDispatch } from "react-redux";
import {
  Spin,
  Button,
  Form,
  message,
  Input,
  InputNumber,
  Row,
  Col,
  Checkbox,
} from "antd";
import get from "lodash/get";
import {
  createSubscription,
  updateSubscription,
} from "../../../redux/actions/subscriptionActions";
import {
  CREATE_SUBSCRIPTION_SUCCESS,
  UPDATE_SUBSCRIPTION_SUCCESS,
} from "../../../constants/message";

const AddSubscription = ({
  edit,
  initialValues,
  toggleSubscriptionModal,
  onSuccess,
}) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [bordcastOnly, setBrodcastOnly] = useState(
    edit ? get(initialValues, "is_broadcast_only") : false
  );

  const onCreateSubscription = (payload) => {
    dispatch(createSubscription(payload))
      .then(() => {
        setLoading(false);
        message.success(CREATE_SUBSCRIPTION_SUCCESS);
        toggleSubscriptionModal();
        onSuccess();
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const onUpdateSubscription = (payload, id) => {
    dispatch(updateSubscription(payload, id))
      .then(() => {
        setLoading(false);
        message.success(UPDATE_SUBSCRIPTION_SUCCESS);
        toggleSubscriptionModal();
        onSuccess();
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const onFinish = (values) => {
    const payload = { ...values, is_broadcast_only: bordcastOnly };
    edit
      ? onUpdateSubscription(payload, get(initialValues, "id"))
      : onCreateSubscription(payload);
  };

  return (
    <Spin spinning={loading}>
      <div className="flex flex-col justify-start">
        <Form
          form={form}
          name="login"
          layout="vertical"
          initialValues={
            edit
              ? {
                  ...initialValues,
                  type: get(initialValues, "type.value"),
                }
              : {}
          }
          onFinish={onFinish}
          onFinishFailed={() => {}}
        >
          <Form.Item
            name="name"
            label="Name"
            rules={[{ required: true, message: "Please input group name!" }]}
          >
            <Input size="large" placeholder="Name" />
          </Form.Item>
          <Row gutter={32}>
            <Col span={12}>
              <Form.Item
                name="no_of_days"
                label="Number of days"
                rules={[
                  { required: true, message: "Please input number of days!" },
                ]}
              >
                <InputNumber
                  className="flex w-full"
                  size="large"
                  placeholder="Number of days"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="price"
                label="Price"
                rules={[{ required: true, message: "Please input price!" }]}
              >
                <InputNumber
                  className="flex w-full"
                  size="large"
                  placeholder="Price"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={32}>
            <Col span={12}>
              <Form.Item
                name="email_broadcast_credit"
                label="Email Credit"
                rules={[
                  { required: true, message: "Please input email credit!" },
                ]}
              >
                <InputNumber
                  className="flex w-full"
                  size="large"
                  placeholder="Email Credit"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="sms_broadcast_credit"
                label="SMS Credit"
                rules={[
                  { required: true, message: "Please input SMS credit!" },
                ]}
              >
                <InputNumber
                  className="flex w-full"
                  size="large"
                  placeholder="SMS Credit"
                />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item name="is_broadcast_only" label="">
            <Checkbox
            disabled={edit}
              checked={bordcastOnly}
              onChange={(val) => {
                setBrodcastOnly(val.target.checked);
              }}
            >
              Only Brodcast
            </Checkbox>
          </Form.Item>
          <div className="w-full flex justify-end space-x-5">
            <Button onClick={() => toggleSubscriptionModal()}>Cancel</Button>
            <Button type="primary" htmlType="submit" onClick={() => {}}>
              {edit ? "Update" : "Add"}
            </Button>
          </div>
        </Form>
      </div>
    </Spin>
  );
};

export default AddSubscription;
