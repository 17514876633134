import get from "lodash/get";
import { LOGIN, LOGOUT } from "../../constants/actionTypes.js";
import { axiosInstance } from "../../utilities/configureAxios.js";

const initialState = {
  user: null,
  token: null,
  isLoggedIn: false,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        token: get(action, "data.token"),
        user: get(action, "data"),
        isLoggedIn: true,
        vendor: get(action, 'data.vendor', []),
      };

    case LOGOUT: {
      return {
        ...state,
        user: null,
        token: null,
        isLoggedIn: false,
        role: null,
      };
    }

    case 'persist/REHYDRATE':
        if (get(action.payload, 'auth.token', null)) {
            axiosInstance.defaults.headers.common.authorization = `Bearer ${get(
                action.payload,
                'auth.token',
                null
            )}`;
        }

    return state;

    default:
      return state;
  }
};

export default authReducer;
