import axios from "axios";
import { notification } from "antd";
import get from "lodash/get";
import head from "lodash/head";

const configureAxios = () =>
  axios.create({
    baseURL: process.env.REACT_APP_API_HOST,
    timeout: 25000,
  });

export const axiosInstance = configureAxios();

axiosInstance.interceptors.request.use((config) => {
  return config;
});

axiosInstance.interceptors.response.use(
  (response) => {
    return response.data.data;
  },
  (err) => {
    if (!err.response) {
      notification.error({
        message: "Something Went Wrong",
        description: "Please Check Your Internet Connection",
      });
    } else {
      if (err.response.status === 401) {
        notification.error({
          message: get(err, "response.data.message", "Something Went Wrong"),
        });
      } else if (err.response.status === 400) {
        notification.error({
          message: get(err, "response.data.message", "Something Went Wrong"),
        });
      } else if (err.response.status === 422) {
        notification.error({
          message: get(err, "response.data.message", "Something Went Wrong"),
        });
      } else {
        let messages = get(err, "response.data.message");
        messages = get(head(messages), "messages");

        notification.error({
          message: get(head(messages), "message", "Something Went Wrong"),
        });
      }
    }
    return Promise.reject(get(err, "response.data"));
  }
);
