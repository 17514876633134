
export const groupTypeOptions = [
    {
        key:"1",
        label : "Email",
        value :0
    },
    {
        key:"2",
        label : "SMS",
        value :1
    },
  ]