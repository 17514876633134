import { Col, Form, Input, Row } from "antd";
import get from "lodash/get";
import LocationFinder from "../../../locationFinder";

const AddressDetails = ({ form, edit, address, setAddress }) => {
  return (
    <div className="flex-grow h-full">
      <Form.Item
        label="Address"
        name="address"
        rules={[{ required: true, message: "Please enter your address" }]}
      >
        <LocationFinder
          edit={edit || address}
          onSuccessAddress={(addresses) => {
            setAddress(addresses);
            form.setFieldsValue({
              ...form.getFieldsValue(),
              address: addresses,
            });
          }}
          placeId={get(address, "place_id")}
        />
      </Form.Item>

      {address && (
        <>
          <Form.Item
            name={["address", "address_1"]}
            label="Address line 1"
            rules={[{ required: true, message: "Please enter address line 1" }]}
          >
            <Input size="large" placeholder="Address line 1" />
          </Form.Item>

          <Form.Item
            name={["address", "address_2"]}
            label="Address line 2"
            rules={[{ required: true, message: "Please enter address line 2" }]}
          >
            <Input size="large" placeholder="Address line 2" />
          </Form.Item>

          <Form.Item
            name={["address", "suburb"]}
            label="Suburb"
            rules={[{ required: true, message: "Please enter suburb" }]}
          >
            <Input size="large" placeholder="Suburb" disabled />
          </Form.Item>

          <p>State</p>
          <Form.Item
            name={["address", "state"]}
            label="State"
            rules={[{ required: true, message: "Please enter state" }]}
          >
            <Input size="large" placeholder="State" disabled />
          </Form.Item>

          <Row gutter={16}>
            <Col>
              <Form.Item name={["address", "city"]} label="City" hidden>
                <Input size="large" placeholder="City" />
              </Form.Item>
              <Form.Item name={["address", "lat"]} label="Latitude" hidden>
                <Input size="large" placeholder="Latitude" />
              </Form.Item>
              <Form.Item name={["address", "lng"]} label="Longitude" hidden>
                <Input size="large" placeholder="Longitude" />
              </Form.Item>
              <Form.Item name={["address", "country"]} label="Country" hidden>
                <Input size="large" placeholder="Country" />
              </Form.Item>
              <Form.Item name={["address", "place_id"]} label="Place Id" hidden>
                <Input size="large" placeholder="Place Id" />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item
            name={["address", "postal_code"]}
            label="Postal Code"
            rules={[{ required: true, message: "Please enter postal code" }]}
          >
            <Input size="large" placeholder="Postal Code" disabled />
          </Form.Item>
        </>
      )}
    </div>
  );
};

export default AddressDetails;
