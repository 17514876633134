import get from "lodash/get";
import map from "lodash/map";
import dayjs from "dayjs";
import { getOrdinalSuffix } from "./helpers";

export const getVehicleDescription = (vehicle) => [
  {
    label: "Brand Name",
    value: <p> {get(vehicle, "brand.name")}</p>,
  },
  {
    label: "Body Type",
    value: <p> {get(vehicle, "body_type.name")} </p>,
  },
  {
    label: "Registration Date",
    value: <p> {get(vehicle, "date_of_registration_formatted")}</p>,
  },
  {
    label: "Registration Expiry Date",
    value: <p> {get(vehicle, "date_registration_exp_formatted")}</p>,
  },
  {
    label: "Making Year",
    value: <p> {get(vehicle, "make_year")}</p>,
  },
  {
    label: "Ownership",
    value: <p> {getOrdinalSuffix(get(vehicle, "ownership"))}</p>,
  },
  {
    label: "RTO State",
    value: <p> {get(vehicle, "rto_state.name")}</p>,
  },
  {
    label: "Km Travelled",
    value: <p> {`${get(vehicle, "km_used")}`}</p>,
  },
  {
    label: "Color",
    value: <p> {`${get(vehicle, "color")}`}</p>,
  },
];

export const getVehicleEngineDescription = (vehicle) => [
  {
    label: "Capacity",
    value: <p>{get(vehicle, "engine_config.capacity")} CC</p>,
  },
  {
    label: "Milage",
    value: <p>{get(vehicle, "engine_config.milage")} Km/Ltr</p>,
  },
  {
    label: "Drive Type",
    value: <p>{get(vehicle, "engine_config.drive_type.name")}</p>,
  },
  {
    label: "Engine Power",
    value: <p>{get(vehicle, "engine_config.engine_power")} BHP</p>,
  },
  {
    label: "Transmission",
    value: <p>{get(vehicle, "engine_config.transmission.name")}</p>,
  },
];

export const ownershipOptions = [
  {
    key: "1",
    label: "1st",
    value: 1,
  },
  {
    key: "2",
    label: "2nd",
    value: 2,
  },
  {
    key: "3",
    label: "3rd",
    value: 3,
  },
];

export const vehicleFormValidation = [
  [
    "name",
    "brand_id",
    "price",
    "model",
    "make_year",
    "date_of_registration",
    "date_registration_exp",
    "fuel_type",
    "body_type",
    "km_used",
    "ownership",
    "rto_state",
    "color",
  ],
  [
    ["engine_config", "transmission"],
    ["engine_config", "engine_power"],
    ["engine_config", "capacity"],
    ["engine_config", "drive_type"],
    ["engine_config", "milage"],
  ],
  ["safety_checks"],
  ["inspection_checks"],
  ["thumbnail_image", "images"],
];

export const brodcastType = [
  { label: "SMS", value: 1 },
  { label: "EMAIL", value: 0 },
];

export const vehicleStatus = [
  { label: "Live", value: 0 },
  { label: "Hold", value: 1 },
  { label: "Sold", value: 2 },
  { label: "Unsold", value: 3 },
];

export const subscriptionTabs = [
  {
    key: "current",
    label: "Active",
  },
  {
    key: "future",
    label: "Upcoming",
  },
  {
    key: "past",
    label: "Past",
  },
];

export const vehicleFormTabs = [
  {
    title: "Basic Info",
  },
  {
    title: "Engine Info",
  },
  {
    title: "Interior & Exterior",
  },
  {
    title: "Safety & Comfort",
  },
  {
    title: "Inspection Checks",
  },
  {
    title: "Images",
  },
];

export const getVehicleInitialValues = (initialValues) => {
  return {
    ...initialValues,
    date_of_registration: dayjs(get(initialValues, "date_of_registration")),
    date_registration_exp: dayjs(get(initialValues, "date_registration_exp")),
    make_year: dayjs(get(initialValues, "make_year"), "YYYY"),
    brand_id: get(initialValues, "brand.value"),
    safety_checks: map(get(initialValues, "safety_checks"), (saftyCheck) =>
      get(saftyCheck, "value")
    ),
    interiors: map(get(initialValues, "interiors"), (interior) =>
      get(interior, "value")
    ),
    comfort_conveniences: map(
      get(initialValues, "comfort_conveniences"),
      (comfort) => get(comfort, "value")
    ),
    exteriors: map(get(initialValues, "exteriors"), (exterior) =>
      get(exterior, "value")
    ),
    inspection_checks: map(
      get(initialValues, "inspection_checks"),
      (inspectionChecks) => get(inspectionChecks, "value")
    ),
    body_type: get(initialValues, "body_type.value"),
    fuel_type: get(initialValues, "fuel_type.value"),
    rto_state: get(initialValues, "rto_state.value"),
    engine_config: {
      ...get(initialValues, "engine_config"),
      drive_type: get(initialValues, "engine_config.drive_type.value"),
      transmission: get(initialValues, "engine_config.drive_type.value"),
    },
  };
};
