import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  Spin,
  Button,
  Form,
  message,
  DatePicker,
  InputNumber,
  Select,
} from "antd";
import get from "lodash/get";
import map from "lodash/map";
import find from "lodash/find";
import debounce from "lodash/debounce";
import isNull from "lodash/isNull";
import dayjs from "dayjs";

import { SUBSCRIPTION_SUBSCRIBE_SUCCESS } from "../../../constants/message";
import {
  fetchSubscription,
  fetchNextSubscriptionDate,
  subscribeSubscription,
} from "../../../redux/actions/subscriptionActions";

const SubscribeSubscriptionForm = ({
  toggleSubscibeSubscriptionDrawer,
  vendor,
  onSuccess,
}) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [subscriptions, setSubsCriptions] = useState([]);
  const [subscriptionDate, setSubscriptionDate] = useState(null);
  const [selectedSubscription, setSelectedSubscription] = useState(null);
  const [discount, setDiscount] = useState(0);

  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const onSubscribeSubscription = (payload, subscriptionId) => {
    setLoading(true);
    dispatch(subscribeSubscription(payload, get(vendor, "id"), subscriptionId))
      .then(() => {
        setLoading(false);
        message.success(SUBSCRIPTION_SUBSCRIBE_SUCCESS);
        toggleSubscibeSubscriptionDrawer();
        onSuccess();
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const getSubscription = () => {
    setLoading(true);
    dispatch(fetchSubscription())
      .then((result) => {
        setLoading(false);
        setSubsCriptions(
          map(result, (subscription) => ({
            ...subscription,
            value: get(subscription, "id"),
            label: `${get(subscription, "name")} (price : ${get(
              subscription,
              "price"
            )})`,
          }))
        );
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const getSubscriptionDate = () => {
    setLoading(true);
    dispatch(fetchNextSubscriptionDate())
      .then((result) => {
        setLoading(false);
        setSubscriptionDate(result);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const onFinish = (values) => {
    const payload = {
      discount: get(values, "discount"),
      start_date: dayjs(get(values, "start_date")).format("YYYY-MM-DD"),
    };
    onSubscribeSubscription(payload, get(values, "subscription"));
  };

  const onChangeDiscount = debounce((d) => {
    setDiscount(d);
  }, 400);

  useEffect(() => {
    getSubscription();
    getSubscriptionDate();
  }, []);

  return (
    <div className="flex flex-col justify-start h-full relative">
      {loading && <Spin spinning={loading} className="spinner-overlay" />}
      <Form
        form={form}
        name="login"
        layout="vertical"
        className="flex flex-col h-full"
        onFinish={onFinish}
        onFinishFailed={() => {}}
      >
        <Form.Item
          name="subscription"
          label="Subscription"
          rules={[{ required: true, message: "Please Select Subscription" }]}
        >
          <Select
            showSearch
            filterOption={filterOption}
            size="large"
            onChange={(val) => {
              const subscription = find(
                subscriptions,
                (subs) => get(subs, "id") === val
              );
              form.setFieldsValue({
                ...form.getFieldsValue(),
                start_date: get(subscription, "is_broadcast_only")
                  ? dayjs()
                  : undefined,
              });

              setSelectedSubscription(subscription);
            }}
            placeholder="Subscription"
            options={subscriptions}
          />
        </Form.Item>
        {selectedSubscription && (
          <Form.Item
            name="start_date"
            label="Start Date"
            rules={[{ required: true, message: "Please select start date!" }]}
          >
            <DatePicker
              size="large"
              format="DD-MM-YYYY"
              className="flex w-full"
              disabled={get(selectedSubscription, "is_broadcast_only")}
              disabledDate={(current) =>
                current && dayjs(current) < dayjs(subscriptionDate)
              }
              onChange={(date) => {}}
            />
          </Form.Item>
        )}
        <Form.Item
          name="discount"
          label="Discount"
          rules={[
            { required: true, message: "Please input discount!" },
            ({}) => ({
              validator(_, value) {
                if (!value || value <= get(selectedSubscription, "price")) {
                  return Promise.resolve();
                }
                if (isNull(selectedSubscription)) {
                  return Promise.reject(
                    new Error("Please select subscription")
                  );
                }
                return Promise.reject(
                  new Error(
                    "Discount should not be greater than susbcription price"
                  )
                );
              },
            }),
          ]}
        >
          <InputNumber
            className="flex w-full"
            size="large"
            placeholder="Discount"
            onChange={(val) => onChangeDiscount(val ? val : 0)}
          />
        </Form.Item>
        {selectedSubscription && (
          <div className="h-full justify-end flex items-end mb-10 w-full">
            <div className="w-1/2 items-end justify-end flex  h-full ">
              <div className="flex w-full justify-end items-end flex-col space-y-1">
                <div className="flex flex-row justify-between w-full">
                  <p className="text-black opacity-45">Total Amount : </p>
                  <p> ₹ {get(selectedSubscription, "price", 0)} </p>
                </div>
                <div className="flex flex-row justify-between w-full">
                  <p className="text-black opacity-45">Discount : </p>
                  <p> ₹ {discount}</p>
                </div>
                <div className="h-[1px] bg-grey-1 w-full" />
                <div className="flex flex-row justify-between w-full">
                  <p className="text-black opacity-45 font-semibold">
                    Payable Amount :{" "}
                  </p>
                  <p> ₹ {get(selectedSubscription, "price", 0) - discount} </p>
                </div>
                <div className="h-[1px] bg-grey-1 w-full" />
              </div>
            </div>
          </div>
        )}

        <div
          className={`${
            selectedSubscription ? "h-fit" : "h-full"
          } justify-end flex items-end `}
        >
          <Button
            type="primary"
            className="flex w-full"
            htmlType="submit"
            onClick={() => {}}
          >
            Subscribe
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default SubscribeSubscriptionForm;
