import { Button, Image } from "antd";

const EmptyComponent = ({
  image,
  heading,
  subHeading,
  buttonText,
  onClick,
}) => {
  return (
    <div className="flex flex-col items-center space-y-3 pb-5 h-full my-10 ">
      {image && <Image height={100} src={image} alt="no data" />}
      <div className="space-y-1 items-center  text-center">
        {heading && <p className="text-base text-black font-bold">{heading}</p>}
        {subHeading && <p className="text-xs text-grey-5">{subHeading}</p>}
      </div>
      {buttonText && (
        <Button type="primary" onClick={onClick}>
          {buttonText}
        </Button>
      )}
    </div>
  );
};

export default EmptyComponent;
