import { Modal, Switch } from "antd";

const { confirm } = Modal;

function ConfirmSwitch({
  active,
  msg,
  okText = "OK",
  cancelText = "Cancel",
  onConfirm,
}) {
  const handleClick = () => {
    if (active) {
      confirm({
        title: msg,
        okText,
        cancelText,
        onOk() {
          onConfirm(false);
        },
      });
    } else {
      onConfirm(true);
    }
  };

  return <Switch onClick={handleClick} checked={active} />;
}

export default ConfirmSwitch;
