import get from "lodash/get";
import map from "lodash/map";
import { getAddressString } from "./helpers";

export const getVendorPayload = (values) => {
  return {
    ...values,
    banner_images: map(get(values, "banner_images"), (bannerImage) =>
      get(bannerImage, "key")
    ),
    logo: get(values, "logo.key"),
  };
};

export const getVendorDescription=(vendorDetails) => [
  {
    key: "1",
    label: "Name",
    children: get(vendorDetails, "name"),
  },
  {
    key: "2",
    label: "Email",
    children: get(vendorDetails, "email"),
  },
  {
    key: "3",
    label: "Phone",
    children: get(vendorDetails, "phone"),
  },
  {
    key: "5",
    label: "Email Brodcast Limit",
    children: get(vendorDetails, "email_broadcast_limit"),
  },
  {
    key: "6",
    label: "SMS Brodcast Limit",
    children: get(vendorDetails, "sms_broadcast_limit"),
  },
  {
    key: "7",
    label: "Address",
    children: getAddressString(get(vendorDetails, "address")),
  },
];

export const getWebsiteDescription=(vendorDetails)=>[
  {
    key: "1",
    label: "Tagline",
    children: get(vendorDetails, "website_details.tag_line"),
  },
  {
    key: "2",
    label: "Description",
    children: get(vendorDetails, "website_details.description"),
  },
]