import { useDispatch, useSelector } from "react-redux";
import { Layout, Dropdown, Avatar, Button, Image } from "antd";
import Gravatar from "react-gravatar";
import get from "lodash/get";
import capitalize from "lodash/capitalize";
import { DownOutlined } from "@ant-design/icons";
import { getMenuItems } from "../../utilities/layout";
import { useNavigate } from "react-router-dom";
import { LOGOUT } from "../../constants/actionTypes";
import { LOGIN } from "../../constants/routes";
import { COLOR_PRIMARY } from "../../constants";
import notificationIcon from "../../static/icons/notification.svg";

const { Header } = Layout;

const LayoutHeader = () => {
  const auth = useSelector((state) => get(state, "auth"));
  const user = get(auth, "user");
  const vendor = get(auth, "vendor");
  const isVendor = get(user, "role.value") === 2;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const onClickLogout = () => {
    dispatch({
      type: LOGOUT,
    });
    navigate(LOGIN);
  };

  return (
    <Header
      style={{
        padding: 0,
        height: 73,
      }}
    >
      <div className=" flex h-full">
        <div className="flex flex-row justify-end items-center space-x-5 px-10 h-full w-full flex-1 ">
          {isVendor && (
            <div className="visit-site-group ">
              <div className="">
                <Button
                  className="custom-ant-btn"
                  style={{
                    borderColor: COLOR_PRIMARY,
                    borderWidth: 2,
                    borderRadius: 12,
                    height: 39,
                  }}
                  onClick={() => window.open(get(vendor, "web_url"))}
                >
                  <p className="text-primary text-xs font-normal hover-white">
                    Visit Site
                  </p>
                </Button>
              </div>
            </div>
          )}
          <div className="flex items-center justify-center hover:cursor-pointer">
            <Image
              width={27}
              preview={false}
              src={notificationIcon}
              alt="notification"
            />
          </div>
          <Dropdown
            menu={{
              items: getMenuItems(navigate, onClickLogout),
            }}
            placement="bottom"
          >
            <div className="flex flex-row items-center justify-center  h-full hover:cursor-pointer min-w-28">
              <Avatar
                className="avtaar"
                icon={<Gravatar email={get(user, "email")} size={20} />}
              />
              <span className="text-black font-semibold text-base items-center pr-4 pl-1  ">
                {capitalize(`${get(user, "name")}`)}
              </span>

              <DownOutlined className="text-primary" />
            </div>
          </Dropdown>
        </div>
      </div>
    </Header>
  );
};

export default LayoutHeader;
