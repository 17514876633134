import { useRef, useState } from "react";
import { Button, Image, Row, Col, Tag, List } from "antd";
import { LeftOutlined, RightOutlined, CheckOutlined } from "@ant-design/icons";
import get from "lodash/get";
import map from "lodash/map";
import capitalize from "lodash/capitalize";

import { scrollContainer } from "../../utilities/helpers";
import { Description } from "../common";
import {
  getVehicleDescription,
  getVehicleEngineDescription,
} from "../../utilities/vehicle";
import { numberToWords } from "../../utilities/helpers";

const VehicleDetails = ({ vehicle }) => {
  const images = [get(vehicle, "thumbnail_image"), ...get(vehicle, "images")];
  const [selectedImage, setSelectedImage] = useState(get(images, "0.url"));
  const containerRef = useRef(null);

  return (
    <Row gutter={32} className="p-5">
      <Col span={11} className="w-full ">
        <div className="w-full ">
          <div className="flex h-full w-full ">
            <div className="flex flex-col w-full ">
              <div className="justify-center items-center relative h-[350px] w-full flex overflow-hidden ">
                <Image
                  className="w-full h-full object-fit absolute top-0 left-0 "
                  src={selectedImage}
                  alt="product image"
                  width={530}
                  height={310}
                  
                  preview={false}
                />
              </div>

              <div className="p-2 mt-5">
                <div className="relative overflow-hidden sm:block hidden ">
                  <div
                    className="flex space-x-4 overflow-x-auto scrollbar-hidden px-4 sm:w-full flex-row "
                    id="container"
                    ref={containerRef}
                  >
                    {map(images, (image, index) => (
                      <div
                        key={index}
                        onClick={() => setSelectedImage(get(image, "url"))}
                        className={`border ${
                          get(image, "url") === selectedImage
                            ? "border-primary"
                            : "border-border"
                        }  min-w-[150px] h-[80px] rounded-[10px] overflow-hidden items-center justify-center `}
                      >
                        <Image
                          width={"100%"}
                          height={"100%"}
                          className=" flex object-cover  "
                          src={get(image, "url")}
                          alt="product image"
                          preview={false}
                        />
                      </div>
                    ))}
                  </div>
                  {images.length > 4 && (
                    <>
                      <div className="absolute left-0 top-0 h-full  flex items-center">
                        <Button
                          shape="circle"
                          onClick={() => scrollContainer(-200, containerRef)}
                        >
                          <div className="flex justify-center ">
                            <LeftOutlined />
                          </div>
                        </Button>
                      </div>
                      <div className="absolute right-0 top-0 h-full  flex items-center">
                        <Button
                          shape="circle"
                          className="item-center flex justify-center"
                          onClick={() => scrollContainer(200, containerRef)}
                        >
                          <div className="flex justify-center ">
                            <RightOutlined />
                          </div>
                        </Button>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-5">
          <div className=" border-border border h-fit w-full rounded-lg overflow-hidden">
            <p className="p-5 text-black text-lg font-semibold">
              Engine Details
            </p>
            {map(getVehicleEngineDescription(vehicle), (ec, index) => (
              <Description index={index} item={ec} />
            ))}
          </div>
        </div>
        {get(vehicle, "interiors", []).length > 0 && (
          <div className="border-border border h-fit w-full rounded-lg overflow-hidden mt-5 ">
            <div className="p-5">
              <p className=" text-xl font-semibold">Interior</p>
            </div>
            <div className="flex flex-col space-y-2  justify-center">
              <List
                dataSource={get(vehicle, "interiors", [])}
                renderItem={(item) => (
                  <List.Item>
                    <div className="flex flex-row space-x-3 px-5">
                      <CheckOutlined />
                      <p className="text-black ">{get(item, "name")}</p>
                    </div>
                  </List.Item>
                )}
              />
            </div>
          </div>
        )}
        {get(vehicle, "exteriors", []).length > 0 && (
          <div className="border-border border h-fit w-full rounded-lg overflow-hidden mt-5 ">
            <div className="p-5">
              <p className=" text-xl font-semibold">Exterior</p>
            </div>
            <div className="flex flex-col space-y-2  justify-center">
              <List
                dataSource={get(vehicle, "exteriors", [])}
                renderItem={(item) => (
                  <List.Item>
                    <div className="flex flex-row space-x-3 px-5">
                      <CheckOutlined />
                      <p className="text-black ">{get(item, "name")}</p>
                    </div>
                  </List.Item>
                )}
              />
              {/* <ul class=" list-disc">
              {map(get(vehicle, "inspection_checks"), (inspectionChecks) => (
                <li className="text-black ">{get(inspectionChecks, "name")}</li>
              ))}
            </ul> */}
            </div>
          </div>
        )}
      </Col>
      <Col span={2} />
      <Col span={11}>
        <div className=" border-border border h-fit w-full rounded-lg overflow-hidden">
          <div className="flex flex-row justify-between">
            <p className=" p-5 text-black text-2xl font-semibold">
              {capitalize(get(vehicle, "name", ""))}
            </p>
            <p className="text-primary p-5  text-2xl font-semibold">
              ₹ {numberToWords(get(vehicle, "price"))}
            </p>
          </div>
          <div className=" px-5 pb-5 flex flex-row space-x-2 ">
            <Tag color="cyan">{get(vehicle, "fuel_type.name")}</Tag>
            <Tag color="blue">
              {get(vehicle, "engine_config.transmission.name")}
            </Tag>
          </div>
          <div className="px-5 pb-5">
            <p className="text-black text-base font-normal">
              {get(vehicle, "description")}
            </p>
          </div>
          <div className="m-5 border-border border rounded-lg overflow-hidden">
            <div className="pt-5">
              <p className="px-5 pb-5 text-xl font-semibold">Overview</p>
            </div>

            {map(getVehicleDescription(vehicle), (pd, index) => (
              <Description index={index} item={pd} />
            ))}
          </div>
        </div>
        {get(vehicle, "safety_checks", []).length > 0 && (
          <div className="border-border border h-fit w-full rounded-lg overflow-hidden mt-5 ">
            <div className="p-5">
              <p className="text-xl font-semibold">Safty Checks</p>
            </div>
            <div className="flex flex-col space-y-2 justify-center">
              <List
                dataSource={get(vehicle, "safety_checks", [])}
                renderItem={(item) => (
                  <List.Item>
                    <div className="flex flex-row space-x-3 px-5">
                      <CheckOutlined />
                      <p className="text-black ">{get(item, "name")}</p>
                    </div>
                  </List.Item>
                )}
              />
            </div>
          </div>
        )}
        {get(vehicle, "inspection_checks", []).length > 0 && (
          <div className="border-border border h-fit w-full rounded-lg overflow-hidden mt-5 ">
            <div className="p-5">
              <p className=" text-xl font-semibold">Inspection Checks</p>
            </div>
            <div className="flex flex-col space-y-2  justify-center">
              <List
                dataSource={get(vehicle, "inspection_checks", [])}
                renderItem={(item) => (
                  <List.Item>
                    <div className="flex flex-row space-x-3 px-5">
                      <CheckOutlined />
                      <p className="text-black ">{get(item, "name")}</p>
                    </div>
                  </List.Item>
                )}
              />
              {/* <ul class=" list-disc">
              {map(get(vehicle, "inspection_checks"), (inspectionChecks) => (
                <li className="text-black ">{get(inspectionChecks, "name")}</li>
              ))}
            </ul> */}
            </div>
          </div>
        )}
        {get(vehicle, "comfort_conveniences", []).length > 0 && (
          <div className="border-border border h-fit w-full rounded-lg overflow-hidden mt-5 ">
            <div className="p-5">
              <p className=" text-xl font-semibold">Comfort & Convenience</p>
            </div>
            <div className="flex flex-col space-y-2  justify-center">
              <List
                dataSource={get(vehicle, "comfort_conveniences", [])}
                renderItem={(item) => (
                  <List.Item>
                    <div className="flex flex-row space-x-3 px-5">
                      <CheckOutlined />
                      <p className="text-black ">{get(item, "name")}</p>
                    </div>
                  </List.Item>
                )}
              />
              {/* <ul class=" list-disc">
              {map(get(vehicle, "inspection_checks"), (inspectionChecks) => (
                <li className="text-black ">{get(inspectionChecks, "name")}</li>
              ))}
            </ul> */}
            </div>
          </div>
        )}
      </Col>
    </Row>
  );
};

export default VehicleDetails;
