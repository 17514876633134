import { useState } from "react";
import { useDispatch } from "react-redux";
import { Form, Select, Input, Button, message, DatePicker } from "antd";
import get from "lodash/get";
import dayjs from "dayjs";

import { getLeadStatus } from "../../../utilities/leads";
import { UPDATE_LEAD_STATUS } from "../../../constants/message";
import { updateLeadStatus } from "../../../redux/actions/LeadsActions";

const { TextArea } = Input;

const LeadStatusForm = ({
  lead,
  toggleLeadStatusForm,
  setLoading,
  onSucess,
}) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [status, setStatus] = useState(null);

  const onFinish = (values) => {
    const payload =
      status === 4
        ? {
            ...values,
            drive_date: dayjs(get(values, "drive_date")).format("YYYY-MM-DD"),
          }
        : values;
    dispatch(updateLeadStatus(payload, get(lead, "id")))
      .then(() => {
        setLoading(false);
        form.resetFields();
        onSucess();
        message.success(UPDATE_LEAD_STATUS);
        toggleLeadStatusForm();
      })
      .catch(() => {
        setLoading(false);
      });
  };

  return (
    <div className="mb-5 ">
      <Form
        className="flex flex-1 flex-col w-full"
        form={form}
        name="login"
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={() => {}}
      >
        <Form.Item
          name="comments"
          label="Comments"
          rules={[{ required: true, message: "Please enter comments!" }]}
        >
          <TextArea
            size="large"
            rows={1}
            placeholder="Comments"
            onChange={(val) => {}}
          />
        </Form.Item>
        <Form.Item
          name="status"
          label="Status"
          rules={[{ required: true, message: "Please select status!" }]}
        >
          <Select
            size="large"
            className="flex w-full"
            placeholder="Status"
            options={getLeadStatus}
            onChange={(val) => setStatus(val)}
          />
        </Form.Item>
        {status === 4 && (
          <Form.Item
            name="drive_date"
            label="Test Drive Date"
            rules={[
              { required: true, message: "Please select test drive date!" },
            ]}
          >
            <DatePicker
              size="large"
              className="flex w-full"
              onChange={() => {}}
            />
          </Form.Item>
        )}

        <Button
          size="large"
          className="mt-[30px]"
          type="primary"
          htmlType="submit"
          onClick={() => {}}
        >
          Update
        </Button>
      </Form>
    </div>
  );
};

export default LeadStatusForm;
