import { Row, Tag } from "antd";
import get from 'lodash/get'
import { CalendarOutlined, ArrowRightOutlined } from "@ant-design/icons";

const SubscriptionCard = ({subscription}) => {
  return (
    <div className="border border-grey-1 rounded-lg p-5">
      <div className="flex flex-row justify-between">
        <div>
          <div className="flex flex-col space-y-2">
            <p className="text-primary text-lg font-semibold">{get(subscription,'name')}</p>
            <div className="flex flex-row space-x-3 items-center">
              <Row className="items-center space-x-1">
                <CalendarOutlined />
                <p className="text-black text-sm">{get(subscription,'vendor_subscription.start_date')}</p>
              </Row>
              <ArrowRightOutlined />
              <Row className="items-center space-x-1">
                <CalendarOutlined />
                <p className="text-black text-sm">
                {get(subscription,'vendor_subscription.end_date')}
                </p>
              </Row>
            </div>
          </div>
          <div className="py-3 space-y-3 flex flex-row justify-between w-full ">
            <div className="flex flex-col space-y-2">
              <div className=" flex flex-row space-x-5 h-fit">
                <Tag color="magenta">Email Credit : {get(subscription,'email_broadcast_credit')}</Tag>
                <Tag color="blue">SMS Credit : {get(subscription,'sms_broadcast_credit')}</Tag>
              </div>
              <div className="flex flex-row  space-x-2 w-full">
                <p className="text-black opacity-45">Number of days : </p>
                <p>{get(subscription,'no_of_days')} </p>
              </div>
            </div>
          </div>
        </div>
        <div className="basis-1/3 items-end justify-end flex ">
          <div className="flex w-full justify-end items-end flex-col space-y-1">
            <div className="flex flex-row justify-between w-full">
              <p className="text-black opacity-45">Total Amount : </p>
              <p> ₹ {get(subscription,'price')} </p>
            </div>
            <div className="flex flex-row justify-between w-full">
              <p className="text-black opacity-45">Discount : </p>
              <p> ₹ {get(subscription,'vendor_subscription.discount')} </p>
            </div>
            <div className="h-[1px] bg-grey-1 w-full" />
            <div className="flex flex-row justify-between w-full">
              <p className="text-black opacity-45 font-semibold">Payable Amount : </p>
              <p> ₹ {get(subscription,'vendor_subscription.payable_amount')} </p>
            </div>
            <div className="h-[1px] bg-grey-1 w-full" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionCard;
