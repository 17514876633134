import { Card, Row, Col, Image } from "antd";
import get from "lodash/get"
import carIcon from "../../../static/icons/menu/car.svg";

const Inventory = ({inventory}) => {
  return (
    <Card bordered={false} style={{ borderRadius: 12 }}>
      <div className="">
        <div className="flex flex-row space-x-5 items-center">
          <div className="h-8 w-8 rounded-lg bg-yellow-1 flex justify-center items-center">
            <Image preview={false} src={carIcon} alt="Inventory" />
          </div> 
          <p className="text-black-2 font-normal text-sm">Inventory</p>
        </div>
        <div className="pt-5">
          <Row>
            <Col span={6} className="space-y-3">
              <p className="text-grey-3 font-normal text-sm">Live</p>
              <div className="flex flex-row space-x-3 items-center">
                <p className="text-black-3 text-xl font-medium">{get(inventory,'0.total',0)}</p>
              </div>
            </Col>
            <Col span={6} className="space-y-3">
              <p className="text-grey-3 font-normal text-sm">Hold</p>
              <div className="flex flex-row space-x-3 items-center">
                <p className="text-black-3 text-xl font-medium">{get(inventory,'1.total',0)}</p>
              </div>
            </Col>
            <Col span={6} className="space-y-3">
              <p className="text-grey-3 font-normal text-sm">Sold</p>
              <div className="flex flex-row space-x-3 items-center">
                <p className="text-black-3 text-xl font-medium">{get(inventory,'2.total',0)}</p>
              </div>
            </Col>
            <Col span={6} className="space-y-3">
              <p className="text-grey-3 font-normal text-sm">Unsold</p>
              <div className="flex flex-row space-x-3 items-center">
                <p className="text-black-3 text-xl font-medium">{get(inventory,'3.total',0)}</p>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </Card>
  );
};

export default Inventory;
