import { LEADS } from "../../constants/apis";
import { getUrl } from "../../utilities/helpers";

export const addLeads =
  (payload) =>
  (dispatch, getState, { api }) =>
    api
      .post(LEADS, payload)
      .then((result) => result)
      .catch((error) => {
        throw error;
      });

export const fetchLeads =
  (params = {}) =>
  (dispatch, getState, { api }) => {
    const url = getUrl(LEADS, { ...params });
    return api
      .get(url)
      .then((result) => result)
      .catch((error) => {
        throw error;
      });
  };

export const updateLeadStatus =
  (payload, uuid) =>
  (dispatch, getState, { api }) =>
    api
      .put(`${LEADS}/${uuid}/status`, payload)
      .then((result) => result)
      .catch((error) => {
        throw error;
      });

export const fetchLeadDetails =
  (uuid) =>
  (dispatch, getState, { api }) =>
    api
      .get(`${LEADS}/${uuid}`)
      .then((result) => result)
      .catch((error) => {
        throw error;
      });
