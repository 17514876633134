import get from "lodash/get";
import assign from "lodash/assign";
import head from "lodash/head";
import { useEffect, useState } from "react";
import GooglePlacesAutocomplete, {
  geocodeByPlaceId,
} from "react-google-places-autocomplete";

const extractAddress = (place) => {
  place = head(place);
  const address = {
    city: "",
    suburb: "",
    postal_code: "",
    country: "",
    address_1: "",
    address_2: "",
    state: "",
  };

  if (!Array.isArray(place.address_components)) {
    return address;
  }

  place.address_components.forEach((component) => {
    const types = get(component, "types");
    const value = get(component, "long_name");

    if (types.includes("subpremise")) {
      address.address_1 = value;
    }

    if (types.includes("street_number")) {
      address.address_1 = address.address_1
        ? `${address.address_1}, ${value}`
        : value;
    }

    if (types.includes("premise")) {
      address.address_1 = address.address_1
        ? `${address.address_1}, ${value}`
        : value;
    }

    if (types.includes("route")) {
      address.address_2 = address.address_2
        ? `${address.address_2},${value}`
        : value;
    }

    if (types.includes("sublocality")) {
      address.address_2 = address.address_2
        ? `${address.address_2},${value}`
        : value;
    }

    if (types.includes("locality")) {
      address.suburb = value;
    }

    if (types.includes("administrative_area_level_2")) {
      address.city = value;
    }
    if (types.includes("administrative_area_level_1")) {
      address.state = value;
    }

    if (types.includes("postal_code")) {
      address.postal_code = value;
    }

    if (types.includes("country")) {
      address.country = value;
    }
  });

  assign(address, {
    place_id: get(place, "place_id"),
    lat: get(place, "geometry.location").lat(),
    lng: get(place, "geometry.location").lng(),
  });

  return address;
};

const LocationFinder = ({ edit, onSuccessAddress, placeId }) => {
  const [address, setAddress] = useState({
    show: false,
    initialValue: null,
  });
  useEffect(() => {
    async function fetchAddress() {
      if (edit && placeId) {
        let intitalAddress = null;
        try {
          intitalAddress = await geocodeByPlaceId(placeId);
        } catch {
          console.log("in error");
        }
        intitalAddress = get(head(intitalAddress), "formatted_address");
        setAddress({
          show: true,
          initialValue: intitalAddress,
        });
      } else {
        setAddress({
          show: true,
          initialValue: null,
        });
      }

      return () => {};
    }
    fetchAddress();
  }, []);

  return (
    get(address, "show") && (
      <GooglePlacesAutocomplete
        apiKey={process.env.REACT_APP_GOOGLE_LOCATION_KEY}
        autocompletionRequest={{
          fields: ["address_component", "geometry"],
        }}
        selectProps={{
          defaultInputValue: get(address, "initialValue", ""),
          placeholder: "Search location...",
          onChange: async (va) => {
            onSuccessAddress(
              extractAddress(await geocodeByPlaceId(get(va, "value.place_id")))
            );
          },
        }}
      />
    )
  );
};

export default LocationFinder;
