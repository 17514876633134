export const DASHBOARD = "/dashbaord";
export const VENDROS = "/vendors";
export const VENDRO_SETTINGS = "/vendors-settings";
export const LOGIN = "/login";
export const FORGOT_PASSWORD = "/forgot-password";
export const PROFILE = "/profile";
export const VEHICLES = "/vehicles";
export const BROADCAST_GROUP = "/broadcast-group";
export const BROADCAST_LOG = "/broadcast-logs";
export const LEADS = "/leads";
export const CHANGE_PASSWORD = "/change-password/:id";
export const SUBSCRIPTION = "/subscription";
export const CONTACT_US = "/contact-us"
