import {
  BRODCAST_GROUPS,
  GENERATE_BRODCAST,
  BRODCAST_LOGS,
} from "../../constants/apis";
import { getUrl } from "../../utilities/helpers";

export const createBrodcastGroup =
  (payload) =>
  (dispatch, getState, { api }) =>
    api
      .post(BRODCAST_GROUPS, payload)
      .then((result) => result)
      .catch((error) => {
        throw error;
      });

export const fetchBrodcastGroups =
  (params = {}) =>
  (dispatch, getState, { api }) => {
    const url = getUrl(BRODCAST_GROUPS, { ...params });
    return api
      .get(url)
      .then((result) => result)
      .catch((error) => {
        throw error;
      });
  };

export const updateBrodcastGroup =
  (payload, uuid) =>
  (dispatch, getState, { api }) =>
    api
      .put(`${BRODCAST_GROUPS}/${uuid}`, payload)
      .then((result) => result)
      .catch((error) => {
        throw error;
      });

export const deleteBrodcastGroup =
  (uuid) =>
  (dispatch, getState, { api }) =>
    api
      .delete(`${BRODCAST_GROUPS}/${uuid}`)
      .then((result) => result)
      .catch((error) => {
        throw error;
      });

export const generateBrodcast =
  (vehicleId, groupId) =>
  (dispatch, getState, { api }) => {
    const url = GENERATE_BRODCAST.replace("{vehicleId}", vehicleId).replace(
      "{groupId}",
      groupId
    );
    return api
      .post(url)
      .then((result) => result)
      .catch((error) => {
        throw error;
      });
  };

export const fetchBrodcastLogs =
  (params = {}) =>
  (dispatch, getState, { api }) => {
    const url = getUrl(BRODCAST_LOGS, { ...params });
    return api
      .get(url)
      .then((result) => result)
      .catch((error) => {
        throw error;
      });
  };
