import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import get from "lodash/get";
import { LOGIN } from "../constants/routes";

const requireAuth = (ChildComponent, roles = []) => {
  const RequireAuth = () => {
    const role = useSelector((state) => get(state, "auth.user.role.value"));
    const isLoggedIn = useSelector((state) => get(state, "auth.isLoggedIn"));
    const navigate = useNavigate();

    useEffect(() => {
      if (!isLoggedIn || (roles.length && roles.indexOf(role) === -1)) {
        navigate(LOGIN); // or '/' if you want to redirect to home
      }
    }, [isLoggedIn, role, roles, navigate]);

    if (!isLoggedIn || (roles.length && roles.indexOf(role) === -1)) {
      return null;
    }

    return <ChildComponent />;
  };

  return RequireAuth;
};

export default requireAuth;
