import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Spin, Table, Select, Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import get from "lodash/get";
import debounce from "lodash/debounce";
import isEmpty from "lodash/isEmpty";
import { BrodcastLogsColumns } from "../../constants/tableColumns/brodcastLogs";
import { fetchBrodcastLogs } from "../../redux/actions/brodcastGroupActions";
import { brodcastType } from "../../utilities/vehicle";

const BrodcastLogScreen = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [logs, setLogs] = useState({
    data: [],
    pagination: { page: 1, limit: 10 },
    filters: {},
  });

  const getBropdcastLogs = (page = 1, filters = {}) => {
    const params = {
      ...get(logs, "filters"),
      page,
      limit: get(logs, "pagination.limit", 1),
      ...filters,
    };
    setLoading(true);
    dispatch(fetchBrodcastLogs(params))
      .then((result) => {
        setLoading(false);
        setLogs({
          data: get(result, "data"),
          pagination: {
            ...get(result, "meta.pagination"),
            limit: get(result, "meta.pagination.per_page"),
          },
          filters: params,
        });
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const onSearchBrodcast = debounce((q) => {
    getBropdcastLogs(1, {
      query: q,
    });
  }, 400);

  useEffect(() => {
    getBropdcastLogs();
  }, []);

  return (
    <div className="p-3 h-full flex flex-col">
      <div className="flex flex-row justify-between pb-8">
        <div>
          <p className="text-xl font-medium leading-[30px] text-black-1">
            Brodcast Logs
          </p>
        </div>
      </div>

      <div className="h-full rounded-xl overflow-hidden bg-white flex-1 overflow-y-auto scrollbar-hidden pb-5 min-w-full">
        <div className="w-full  px-8 py-6">
          <div className="justify-end flex items-center h-full space-x-5">
            <Select
              style={{
                minWidth: 200,
              }}
              mode="multiple"
              size="middle"
              placeholder="Type"
              options={brodcastType}
              onChange={(val) =>
                getBropdcastLogs(1, {
                  type: isEmpty(val) ? undefined : String(val),
                })
              }
            />
            <Input
              style={{
                width: 250,
              }}
              size="middle"
              onChange={(val) => onSearchBrodcast(val.target.value)}
              placeholder="Search Vehicle or Group"
              prefix={<SearchOutlined className="mr-2" />}
            />
          </div>
        </div>
        <div className="px-8">
          <Spin spinning={loading}>
            <Table
              columns={BrodcastLogsColumns()}
              dataSource={get(logs, "data")}
              pagination={{
                pageSize: get(logs, "pagination.per_page", 1),
                current: get(logs, "pagination.current_page", 1),
                total: get(logs, "pagination.total", 1),
                onChange: (val) => {
                  getBropdcastLogs(val);
                },
              }}
            />
          </Spin>
        </div>
      </div>
    </div>
  );
};

export default BrodcastLogScreen;
