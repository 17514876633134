import { Row, Col, Card, Image } from "antd";
import get from "lodash/get"
import brodcastingIcon from "../../../static/icons/menu/brodcast.svg";

const BrodcastingCredits = ({brodcastingCredits}) => {
  return (
    <Card bordered={false} style={{ borderRadius: 12 }}>
      <div >
        <div className="flex flex-row space-x-5 items-center">
          <div className="h-8 w-8 rounded-lg bg-yellow-1 flex justify-center items-center">
            <Image preview={false} src={brodcastingIcon} alt="Leads" />
          </div>
          <p className="text-black-2 font-normal text-sm">
            Brodcasting Credits
          </p>
        </div>
        <div className="pt-5">
          <Row>
            <Col span={12} className="space-y-3">
              <div className="flex flex-row space-x-2 items-center">
                <p className="text-grey-3 font-normal text-sm">Emails</p>
              </div>

              <div className="flex flex-row space-x-3 items-center">
                <p className="text-black-3 text-xl font-medium">{get(brodcastingCredits,'email.remainig',0)}/{get(brodcastingCredits,'email.total',0)}</p>
              </div>
            </Col>
            <Col span={12} className="space-y-3">
              <div className="flex flex-row space-x-2 items-center">
                <p className="text-grey-3 font-normal text-sm">SMS</p>
              </div>

              <div className="flex flex-row space-x-3 items-center">
                <p className="text-black-3 text-xl font-medium">{get(brodcastingCredits,'sms.remainig',0)}/{get(brodcastingCredits,'sms.total',0)}</p>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </Card>
  );
};

export default BrodcastingCredits;
