import logo from "../../static/images/logo.png";
import { Button, Image } from "antd";
import { COLOR_PRIMARY } from "../../constants";
import { CONTACT_US } from "../../constants/routes";

const LandingHeader = () => {
  return (
    <div className="flex flex-col sm:h-[100px] h-[56px] justify-center w-full bg-grey-8 grid-lines ">
      <div className="sm:px-16 px-5 flex flex-row justify-between items-center">
        <div className="flex justify-between w-full sm:w-fit ">
          <div className="hover:cursor-pointer flex sm:h-10 sm:w-44 w-36 relative justify-center items-center h-7">
            <Image src={logo} alt="logo" preview={false} />
          </div>
        </div>
        <div className="flex-row space-x-5 flex">
          <Button
            onClick={() => {
              window.open(
                `${process.env.REACT_APP_WEBSITE_HOST}${CONTACT_US}`,
                "_self"
              );
            }}
            style={{ borderColor: COLOR_PRIMARY, borderRadius: 12, height: 44 }}
          >
            <p className="text-primary">Contact Us</p>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default LandingHeader;
