import get from "lodash/get"
import { Tag } from "antd";
import upperCase from 'lodash/upperCase'

const getTagColor = (val) => {
  switch (val) {
    case 0:
      return "purple";
    case 1:
      return "cyan";
    default:
      return "purple";
  }
};

export const BrodcastLogsColumns=()=>[
    {
        title: "Type",
        key: "type",
        dataIndex: "type",
        render: (type) => (
          <Tag color={getTagColor(get(type, "value"))}>{upperCase(get(type, "name"))}</Tag>
        ),
      },
      {
        title: "Group",
        key: "group",
        render:(record)=> get(record,'group.name')
      },
      {
        title: "Vehicle",
        key: "vehicle",
        render:(record)=> get(record,'vehicle.name')
      },
      {
        title: "Credits",
        dataIndex: "credits",
        key: "credits",
      },
      {
        title: "Date",
        dataIndex: "created_at_formatted",
        key: "created_at_formatted",
        width : 300
      }

]