import {useState} from 'react';
import {message, Spin, Upload} from 'antd';
import {useSelector} from 'react-redux';
import get from 'lodash/get';
import {LoadingOutlined, PlusOutlined} from '@ant-design/icons';
import { IMAGE_UPLOAD } from '../../constants/apis';

const ImageUpload = (props) => {
    const {imageUrl, onUploadDone} = props;
    const token = useSelector((state) => get(state, 'auth.token'));
    const [loading, setLoading] = useState(false);
    const uploadButton = (
        <div>
            {loading ? <LoadingOutlined/> : <PlusOutlined/>}
            <div style={{marginTop: 8}}>Upload</div>
        </div>
    );
    const handleChange = (info) => {
        if (info.file.status === 'uploading') {
            setLoading(true);
            return;
        }
        if (info.file.status === 'done') {
            get(info, 'file.response');
            setLoading(false);
            onUploadDone(get(info, 'file.response.data'));
        } else {
            setLoading(false);
        }
    };

    const handleBeforeUpload=(file)=> {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('You can only upload JPG/PNG file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 6;
        if (!isLt2M) {
            message.error('Image must smaller than 2MB!');
        }
        return isJpgOrPng && isLt2M;
    }

    return (
        <Spin spinning={loading}>
            <Upload
                name="file"
                listType="picture-card"
                className="avatar-uploader"
                showUploadList={false}
                action={IMAGE_UPLOAD}
                beforeUpload={handleBeforeUpload}
                headers={{Authorization: `Bearer ${token}`}}
                onChange={handleChange}
            >
                {imageUrl ? (
                    <img src={imageUrl} alt="avatar" style={{width: '100%'}}/>
                ) : (
                    uploadButton
                )}
                
            </Upload>
        </Spin>
    );
};

export default ImageUpload;
