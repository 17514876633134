import { Card, Image, Tooltip } from "antd";
import map from "lodash/map";
import get from "lodash/get";
import carIcon from "../../../static/icons/menu/car.svg";
import noResultIcon from "../../../static/icons/noResult.svg";
import { EyeFilled } from "@ant-design/icons";
import { COLOR_PRIMARY } from "../../../constants";
import EmptyComponent from "../../empty";

const MostViewedVehicle = ({ vehicles = [] }) => {
  return (
    <Card bordered={false} style={{ borderRadius: 12 }}>
      <div>
        <div className="flex flex-row space-x-5 items-center">
          <div className="h-8 w-8 rounded-lg bg-yellow-1 flex justify-center items-center">
            <Image preview={false} src={carIcon} alt="Inventory" />
          </div>
          <p className="text-black-2 font-normal text-sm">Most Viewed Cars</p>
        </div>
        <div className="pt-5">
          <div className="space-y-4">
            {vehicles.length === 0 ? (
              <EmptyComponent
                image={noResultIcon}
                heading={"No vehicle found"}
              />
            ) : (
              map(vehicles, (vehicle, index) => (
                <div
                  key={index}
                  className="flex flex-row justify-between items-center"
                >
                  <div className="flex flex-row space-x-5">
                    <Image
                      src={get(vehicle, "thumbnail_image.url")}
                      alt="most viewed car"
                      width={90}
                      height={60}
                      className="flex top-0 left-0 object-cover h-full w-full rounded-lg "
                    />
                    <div className="flex flex-col space-y-2">
                      <p className="text-black-3 text-sm font-normal">
                        {get(vehicle, "name")}
                      </p>
                      <p className="text-black-2 text-sm font-medium">
                        ₹{get(vehicle, "price")}
                      </p>
                    </div>
                  </div>
                  <div className="flex flex-row space-x-1 items-center">
                    <p className="text-sm font-normal text-primary">
                      {get(vehicle, "views", 0)}
                    </p>
                    <Tooltip
                      placement="topLeft"
                      title={`${get(vehicle, "views", 0)} views on ${get(
                        vehicle,
                        "name"
                      )}`}
                    >
                      <EyeFilled style={{ color: COLOR_PRIMARY }} className="hover:cursor-pointer" />
                    </Tooltip>
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
      </div>
    </Card>
  );
};

export default MostViewedVehicle;
