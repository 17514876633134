import {
  SUBSCRIPTIONS,
  SUBSCRIBE_SUBSCRIPTION,
  VENDORS,
  VENDOR_SUBSCRIPTIONS,
} from "../../constants/apis";

export const createSubscription =
  (payload) =>
  (dispatch, getState, { api }) =>
    api
      .post(SUBSCRIPTIONS, payload)
      .then((result) => result)
      .catch((error) => {
        throw error;
      });

export const fetchSubscription =
  () =>
  (dispatch, getState, { api }) => {
    return api
      .get(SUBSCRIPTIONS)
      .then((result) => result)
      .catch((error) => {
        throw error;
      });
  };

export const updateSubscription =
  (payload, uuid) =>
  (dispatch, getState, { api }) =>
    api
      .put(`${SUBSCRIPTIONS}/${uuid}`, payload)
      .then((result) => result)
      .catch((error) => {
        throw error;
      });

export const deleteSubscription =
  (uuid) =>
  (dispatch, getState, { api }) =>
    api
      .delete(`${SUBSCRIPTIONS}/${uuid}`)
      .then((result) => result)
      .catch((error) => {
        throw error;
      });

export const subscribeSubscription =
  (payload, vendorId, subscriptionId) =>
  (dispatch, getState, { api }) =>
    api
      .post(
        SUBSCRIBE_SUBSCRIPTION.replace("{vendorId}", vendorId).replace(
          "{subscriptionId}",
          subscriptionId
        ),
        payload
      )
      .then((result) => result)
      .catch((error) => {
        throw error;
      });

export const fetchNextSubscriptionDate =
  (uuid) =>
  (dispatch, getState, { api }) => {
    return api
      .get(`${VENDORS}/${uuid}/next-subscription-date`)
      .then((result) => result)
      .catch((error) => {
        throw error;
      });
  };

export const fetchVendorSubscription =
  (uuid) =>
  (dispatch, getState, { api }) => {
    return api
      .get(VENDOR_SUBSCRIPTIONS.replace("{uuid}", uuid))
      .then((result) => result)
      .catch((error) => {
        throw error;
      });
  };
