import { Card, Row, Col, Image } from "antd";
import map from "lodash/map";
import get from "lodash/get";
import leadsIcon from "../../../static/icons/menu/leads.svg";
import { getDashboardLeads } from "../../../utilities/leads";
import { Chart } from "react-google-charts";

const Leads = ({ leads }) => {

  const data = [
    ["Leads", "Leads Count "],
    ["New", get(leads, "0.total", 0)],
    ["Contacted", get(leads, "1.total", 0)],
    ["Follow Ups", get(leads, "2.total", 0)],
    ["Closed", get(leads, "3.total", 0)],
  ];

  const options = {
    legend: "none",
    pieHole: 0.7,
    pieSliceText: "none",
    is3D: false,
    colors: map(getDashboardLeads(leads), (lead) => get(lead, "color")),
    chartArea: { width: "80%", height: "100%" },
    backgroundColor: "transparent",
  };

  return (
    <Card bordered={false} style={{ borderRadius: 12 }}>
      <div className="">
        <div className="flex flex-row space-x-5 items-center">
          <div className="h-8 w-8 rounded-lg bg-yellow-1 flex justify-center items-center">
            <Image preview={false} src={leadsIcon} alt="Leads" />
          </div>
          <p className="text-black-2 font-normal text-sm">Leads</p>
        </div>
        <div className="pt-5">
          <Row>
            {map(getDashboardLeads(leads), (lead) => (
              <Col span={6} className="space-y-3" key={get(lead, "label")}>
                <div className="flex flex-row space-x-2 items-center">
                  <div
                    className="h-2 w-2 rounded-full"
                    style={{ backgroundColor: get(lead, "color") }}
                  />
                  <p className="text-grey-3 font-normal text-sm">
                    {get(lead, "label")}
                  </p>
                </div>

                <div className="flex flex-row space-x-3 items-center">
                  <p className="text-black-3 text-xl font-medium">
                    {get(lead, "value",0)}
                  </p>
                </div>
              </Col>
            ))}
          </Row>
        </div>
        <div className="pt-5 flex justify-center">
          <div className="w-[220px] relative h-[220px] bg-[#EEF0FA] rounded-full overflow-visible flex items-center justify-center">
            <div className="h-[75px] w-[75px] absolute rounded-full bg-white" />
            <Chart
              chartType="PieChart"
              width="220px"
              height="220px"
              data={data}
              options={options}
            />
          </div>
        </div>
      </div>
    </Card>
  );
};

export default Leads;
