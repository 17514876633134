import { useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Col, Form, Input, message, Row, Spin } from "antd";
import { PlusOutlined, EditFilled } from "@ant-design/icons";
import get from "lodash/get";
import {
  createVendorAdmin,
  updateVendorAdmin,
} from "../../../redux/actions/vendorAdminActions";
import {
  CREATE_VENDOR_ADMIN_SUCCESS,
  UPDATE_VENDOR_ADMIN_SUCCESS,
} from "../../../constants/message";

const VendorAdminForm = ({ vendors, edit, initialValues, onSuccess }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(undefined);

  const onCreateAdmin = (payload, vendorId) => {
    setLoading(true);
    dispatch(createVendorAdmin(payload, vendorId))
      .then((result) => {
        setLoading(false);
        onSuccess();
        message.success(CREATE_VENDOR_ADMIN_SUCCESS);
      })
      .catch((error) => {
        setError(get(error, "errors.email.0"));
        setLoading(false);
      });
  };

  const onUpdatedAdmin = (payload, vendorId, adminId) => {
    setLoading(true);
    dispatch(updateVendorAdmin(payload, vendorId, adminId))
      .then((result) => {
        setLoading(false);
        onSuccess();
        message.success(UPDATE_VENDOR_ADMIN_SUCCESS);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const onFinish = (values) => {
    const vendorId = get(vendors, "id");
    !edit
      ? onCreateAdmin(values, vendorId)
      : onUpdatedAdmin(values, vendorId, get(initialValues, "id"));
  };

  const onFinishFailed = () => {};

  return (
    <Form
      scrollToFirstError
      form={form}
      layout="vertical"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      initialValues={edit ? initialValues : {}}
      className="mt-8"
    >
      <Spin spinning={loading}>
        <Row gutter={16}>
          <Col xl={12} xs={24}>
            <Form.Item
              name="name"
              label="Name"
              rules={[{ required: true, message: "Please enter first name" }]}
            >
              <Input size="large" placeholder="First Name" />
            </Form.Item>
          </Col>

          <Col xl={12} xs={24}>
            <Form.Item
              name="email"
              label="Email"
              rules={[
                { required: true, message: "Please input  Email!" },
                {
                  type: "email",
                  message: "Please input valid email",
                },
                {
                  pattern: new RegExp(
                    /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/
                  ),
                  message: "Email should not contain special characters!",
                },
              ]}
              validateStatus={error ? "error" : ""}
              help={error}
            >
              <Input size="large" placeholder="Email" />
            </Form.Item>
          </Col>
          <Col xl={12} xs={24}>
            <Form.Item
              name="phone"
              label="Mobile Number"
              rules={[
                { required: true, message: "Please input  Mobile Number" },
                {
                  pattern: /^[6-9]\d{9}$/,
                  message: "Please enter valid phone number",
                },
              ]}
            >
              <Input size="large" placeholder="Mobile number" />
            </Form.Item>
          </Col>

          <Col xl={12} xs={24}>
            <Form.Item
              label="Vendor"
              rules={[{ required: true, message: "Please select Vendor" }]}
            >
              <Input size="large" value={get(vendors, "name")} disabled />
            </Form.Item>
          </Col>
        </Row>

        <Row className="mt-8 justify-end">
          <Col xl={8} xs={24}>
            <Button
              icon={edit ? <EditFilled /> : <PlusOutlined />}
              type="primary"
              block
              htmlType="submit"
            >
              {edit ? "Update" : "Add"}
            </Button>
          </Col>
        </Row>
      </Spin>
    </Form>
  );
};

export default VendorAdminForm;
