import { Tooltip, Space, Tag } from "antd";
import get from "lodash/get";
import { EditTwoTone, DeleteTwoTone } from "@ant-design/icons";
import { COLOR_PRIMARY } from "../../constants";

export const SubscriptionColumns = ({
  toggleSubscriptionModal,
  onClickDelete,
}) => [
  {
    title: "Name",
    key: "name",
    render: (record) => (
      <div className="flex flex-col">
        {get(record, "name")}{" "}
        
        {get(record, "is_broadcast_only") && <div className="h-fit pt-2"> <Tag color="purple" className=" h-fit">Brodcast</Tag></div> }{" "}
      </div>
    ),
  },
  {
    title: "Days",
    dataIndex: "no_of_days",
    key: "no_of_days",
  },
  {
    title: "Price",
    dataIndex: "price",
    key: "price",
  },
  {
    title: "Brodcast Credits",
    key: "price",
    render: (record) => (
      <div className="flex flex-row w-fit space-x-5">
        <Tag color="magenta">
          Email : {get(record, "email_broadcast_credit")}
        </Tag>
        <Tag color="blue">SMS : {get(record, "sms_broadcast_credit")}</Tag>
      </div>
    ),
  },
  {
    title: "",
    key: "action",
    align: "center",
    render: (record) => (
      <Space size={15}>
        <Tooltip title="Edit Subscription">
          <EditTwoTone
            style={{ fontSize: 20 }}
            twoToneColor={COLOR_PRIMARY}
            onClick={() => {
              toggleSubscriptionModal(record);
            }}
          />
        </Tooltip>
        <Tooltip title="Delete Subscription">
          <DeleteTwoTone
            style={{ fontSize: 20 }}
            twoToneColor={COLOR_PRIMARY}
            onClick={() => {
              onClickDelete(record);
            }}
          />
        </Tooltip>
      </Space>
    ),
  },
];
