import { Layout, ConfigProvider } from "antd";
import { BrowserRouter as Router } from "react-router-dom";
import { useSelector } from "react-redux";
import get from "lodash/get";
import LayoutSider from "./components/layout/LayoutSider";
import LayoutHeader from "./components/layout/LayoutHeader";
import AuthRouter from "./router/AuthRouter";
import VendorRouter from "./router/VendorRouter";
import AdminRouter from "./router/AdminRouter";
import { COLOR_PRIMARY, SIDEBAR_COLOR } from "./constants";

const { Content } = Layout;

const AppRouter = () => {
  const isLoggedIn = useSelector((state) => get(state, "auth.isLoggedIn"));
  const user = useSelector((state) => get(state, "auth.user"));
  const isAdmin = get(user, "role.value") === 1;

  return (
    <ConfigProvider
      theme={{
        components: {
          Layout: {
            bodyBg: "#FFFFFF",
            headerBg: "#FFFFFF",
            triggerBg: "#FFFFFF",
            siderBg: SIDEBAR_COLOR,
          },
          Menu: {
            colorItemTextSelected: "white",
            colorItem: "white",
            // colorItemText: "white",
            itemSelectedBg: COLOR_PRIMARY,
            itemColor: "#53545C",

            itemSelectedColor: "white",
          },
        },
        token: {
          colorPrimary: COLOR_PRIMARY,
          colorBgContainer: "#ffffff",
          colorBgLayout: "#ffffff",
        },
      }}
    >
      <Router>
        <Layout className="h-screen bg-[#EEF0FA">
          {isLoggedIn && <LayoutSider />}

          <Layout style={{ flexDirection: "column", flexGrow: 1 }}>
            {isLoggedIn && <LayoutHeader />}

            <Content
              className={`${isLoggedIn && "px-5 pt-5 pb-4 bg-[#EEF0FA]  "} `}
            >
              <div className=" overflow-y-scroll h-full scrollbar-hidden">
                <AuthRouter />
                <VendorRouter />
                <AdminRouter />
              </div>
            </Content>
          </Layout>
        </Layout>
      </Router>
    </ConfigProvider>
  );
};

export default AppRouter;
