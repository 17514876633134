import { Tooltip } from "antd";
import { EditTwoTone } from "@ant-design/icons";
import { COLOR_PRIMARY } from "../../constants";

export const VendorAdminColumns = ({ toggleAdminFormModal }) => [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Contact",
    dataIndex: "phone",
    key: "phone",
  },
  {
    title: "Email",
    dataIndex: "email",
    key: "email",
  },
  {
    title: "",
    key: "action",
    align: "center",
    render: (record) => (
      <Tooltip title="Edit Vendor">
        <EditTwoTone
          style={{ fontSize: 20 }}
          twoToneColor={COLOR_PRIMARY}
          onClick={() => {
            toggleAdminFormModal(record);
          }}
        />
      </Tooltip>
    ),
  },
];
