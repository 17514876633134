import ForgotPasswordForm from "../../components/forms/forgotPasswordForm";
import LandingHeader from "../../components/landingHeader";

const ForgotPasswordScreen = () => {
  return (
    <div className="w-full justify-center items-center bg-grey-8 forgot-password-grid-lines min-h-full bg-[#F7F8F9]">
      <LandingHeader />
      <div className="  justify-center flex mt-20 sm:p-0 p-5 items-center h-full">
        <div className="w-[423px] bg-white shadow rounded-xl ">
          <div className="sm:p-10 p-5 ">
            <div className="space-y-2 pb-10">
              <p className="text-black text-xl leading-[30px] font-medium text-center">
                Forgot Password
              </p>
              <p className="text-[#8B8D97] text-sm font-normal text-center">
              Enter your email address below, and we'll send you a link to reset your password.
              </p>
            </div>
            <ForgotPasswordForm />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPasswordScreen;
