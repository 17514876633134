import { Upload, Modal, message } from "antd";
import { useEffect, useState } from "react";
import get from "lodash/get";
import map from "lodash/map";
import remove from "lodash/remove";
import findIndex from "lodash/findIndex";
import { useSelector } from "react-redux";
import { PlusOutlined, ExclamationCircleFilled } from "@ant-design/icons";
import { IMAGE_UPLOAD } from "../../constants/apis";

const { confirm } = Modal;

const ImageListUpload = (props) => {
  const { setLoading, setImages, images, onUploadDone } = props;

  const token = useSelector((state) => get(state, "auth.token"));
  const [imagePreview, setImagePreview] = useState({
    previewImage: null,
    previewVisible: false,
  });

  const [fileList, setFileList] = useState([]);

  useEffect(() => {
    setFileList(
      map(images, (qm) => {
        return {
          uid: get(qm, "key"),
          name: get(qm, "key"),
          status: "done",
          url: get(qm, "url"),
          linked: true,
        };
      })
    );
  }, [images]);

  const handleImagePreview = (file) => {
    let index = findIndex(fileList, ["uid", get(file, "uid")]);
    setImagePreview({
      previewImage: get(images, `${index}.url`),
      previewVisible: true,
    });
  };

  const handleChange = (info) => {
    if (get(info, "file.status") === "uploading") {
      setLoading(true);
    } else if (get(info, "file.status") === "done") {
      onUploadDone([...images, get(info, "file.response.data")]);
      setImages([...images, get(info, "file.response.data")]);
      setLoading(false);
    } else {
      setLoading(false);
    }
    setFileList([...info.fileList]);
  };

  function beforeUpload(file) {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 6;
    if (!isLt2M) {
      message.error(
        "Image must be smaller than 2MB! This image can not be uploaded."
      );
    }
    return isJpgOrPng && isLt2M;
  }

  const onRemoveImage = (file) => {
    return new Promise((resolve) => {
      confirm({
        title: "Are you Sure !",
        icon: <ExclamationCircleFilled />,
        content: "This action can not be undone.",
        onOk() {
          let newImages = [...images];
          // dispatch(removeMedia([get(file, "name")]));
          remove(
            newImages,
            (i, index) =>
              index === findIndex(fileList, ["uid", get(file, "uid")])
          );
          setImages(newImages);
          //   message.success("Image delete successfully");
          resolve(true);
        },
        onCancel() {
          resolve(false);
        },
      });
    });
  };

  return (
    <>
      <Upload
        listType="picture-card"
        fileList={fileList}
        multiple
        action={IMAGE_UPLOAD}
        headers={{ Authorization: `Bearer ${token}` }}
        beforeUpload={beforeUpload}
        onPreview={handleImagePreview}
        onChange={handleChange}
        onRemove={onRemoveImage}
      >
        <div>
          <PlusOutlined />
          <div className="ant-upload-text">Upload</div>
        </div>
      </Upload>
      {get(imagePreview, "previewVisible") && (
        <Modal
          className="remove-modal-padding"
          open={get(imagePreview, "previewVisible")}
          footer={null}
          onCancel={() => {
            setImagePreview({
              previewVisible: false,
              previewImage: null,
            });
          }}
          closeIcon={false}
        >
          <img
            alt="example"
            style={{ width: "100%" }}
            src={get(imagePreview, "previewImage")}
          />
        </Modal>
      )}
    </>
  );
};

export default ImageListUpload;
