import { useState } from "react";
import { useDispatch } from "react-redux";
import { Form, Spin, Row, Col, Select, Button, message } from "antd";
import get from "lodash/get";
import { vehicleStatus } from "../../../utilities/vehicle";
import { updateVehicleStatus } from "../../../redux/actions/vehiclesAction";
import { UPDATE_VEHICLE_STATUS } from "../../../constants/message";

const VehicleStatusForm = ({ toggleUpdateStatusModal, onSuccess, data }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const onFinish = (values) => {
    setLoading(true);
    dispatch(updateVehicleStatus(values, get(data, "id")))
      .then((result) => {
        setLoading(false);
        message.success(UPDATE_VEHICLE_STATUS);
        toggleUpdateStatusModal();
        onSuccess();
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const onFinishFailed = () => {};

  return (
    <Form
      scrollToFirstError
      layout="vertical"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      initialValues={{ status: get(data, "status.value") }}
    >
      <Spin spinning={loading}>
        <Row gutter={16} className="my-5">
          <Col xl={24} xs={24}>
            <Form.Item name="status" label="">
              <Select
                className="flex w-full"
                size="large"
                onChange={() => {}}
                placeholder="Status"
                options={vehicleStatus}
              />
            </Form.Item>
          </Col>
        </Row>
      </Spin>
      <div className="flex flex-row space-x-5 justify-end">
        <Button type="primary" htmlType="submit">
          {" "}
          Update
        </Button>
      </div>
    </Form>
  );
};

export default VehicleStatusForm;
