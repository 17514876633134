import { Row, Form, Input } from "antd";
import get from "lodash/get";
import ImageUpload from "../../../imageUpload";
import ImageListUpload from "../../../imageListUpload";

const VehicleImages = ({
  form,
  setThumbnailImage,
  images,
  setImages,
  thumbnailImage,
  setLoading,
}) => {
  return (
    <>
      <Row>
        <Form.Item
          label="Thumbnail Image"
          name="thumbnail_image"
          rules={[{ required: true, message: "Please select thumbnail image" }]}
        >
          <ImageUpload
            onUploadDone={(imageObj) => {
              setThumbnailImage(imageObj);
              form.setFieldsValue({
                ...form.getFieldsValue(),
                thumbnail_image: imageObj,
              });
            }}
            imageUrl={get(thumbnailImage, "url")}
          />
        </Form.Item>
      </Row>
      <Row>
        <Form.Item
          name="images"
          label="Images"
          rules={[{ required: true, message: "Please select images" }]}
        >
          <ImageListUpload
            setLoading={setLoading}
            images={images}
            edit={false}
            setImages={setImages}
            onUploadDone={(images) =>
              form.setFieldsValue({
                ...form.getFieldsValue(),
                images: images,
              })
            }
          />
        </Form.Item>
      </Row>
      <Form.Item
        label="Description"
        name={"description"}
        rules={[{ required: true, message: "Please enter description" }]}
      >
        <Input.TextArea
          size="large"
          placeholder="Description"
        />
      </Form.Item>
    </>
  );
};

export default VehicleImages
