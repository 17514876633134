import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Tabs, Drawer, Button, Spin, Empty } from "antd";
import { subscriptionTabs } from "../../utilities/vehicle";
import get from "lodash/get";
import assign from "lodash/assign";
import map from "lodash/map";

import SubscribeSubscriptionForm from "../forms/subscribeSubscriptionForm";
import { fetchVendorSubscription } from "../../redux/actions/subscriptionActions";
import SubscriptionCard from "../subscriptionCard";

const VendorSubscription = ({ vendor }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => get(state, "auth.user"));
  const [loading, setLoading] = useState(false);
  const [currentTab, setCurrentTab] = useState("current");
  const [subscibeSubscriptionDrawer, setSubscibeSubscriptionDrawer] = useState({
    visible: false,
    data: null,
  });

  const [vendorSubscription, setVendorSubscription] = useState({});

  const toggleSubscibeSubscriptionDrawer = (data = null) => {
    assign(subscibeSubscriptionDrawer, {
      visible: !get(subscibeSubscriptionDrawer, "visible"),
      data,
    });
    setSubscibeSubscriptionDrawer({ ...subscibeSubscriptionDrawer });
  };

  const getSubscription = () => {
    setLoading(true);
    dispatch(fetchVendorSubscription(get(vendor, "id")))
      .then((result) => {
        setLoading(false);
        setVendorSubscription(result);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getSubscription();
  }, []);

  return (
    <>
      {loading && <Spin spinning={loading} className="spinner-overlay" />}
      <div className="flex flex-col w-full h-full">
        <Tabs
          defaultActiveKey="1"
          items={subscriptionTabs}
          onChange={(val) => {
            setCurrentTab(val);
          }}
          tabBarExtraContent={
            get(user, "role.value") === 1 && (
              <Button
                type="primary"
                onClick={() => toggleSubscibeSubscriptionDrawer(vendor)}
              >
                Subscribe
              </Button>
            )
          }
        />
        <div className="flex flex-col overflow-y-auto scrollbar-hidden">
          {get(vendorSubscription, currentTab) ? (
            <div className="space-y-5">
              {map(get(vendorSubscription, currentTab), (subscription) => (
                <SubscriptionCard subscription={subscription} />
              ))}
            </div>
          ) : (
            <Empty />
          )}
        </div>

        {get(subscibeSubscriptionDrawer, "visible") && (
          <Drawer
            title={`Subscribe subscription`}
            destroyOnClose
            width={"45%"}
            onClose={() => toggleSubscibeSubscriptionDrawer()}
            open={get(subscibeSubscriptionDrawer, "visible")}
            footer={null}
          >
            <SubscribeSubscriptionForm
              toggleSubscibeSubscriptionDrawer={
                toggleSubscibeSubscriptionDrawer
              }
              vendor={get(subscibeSubscriptionDrawer, "data")}
              onSuccess={() => getSubscription()}
            />
          </Drawer>
        )}
      </div>
    </>
  );
};

export default VendorSubscription;
