import { Routes, Route } from "react-router-dom";
import {
  DASHBOARD,
  VEHICLES,
  VENDRO_SETTINGS,
  BROADCAST_GROUP,
  LEADS,
  BROADCAST_LOG
} from "../constants/routes";
import DashboardScreen from "../screens/DashboardScreen";
import VendorSettingScreen from "../screens/VendorSettingScreen";
import VehicleScreen from "../screens/VehicleScreen";
import BroadcastGroupScreen from "../screens/BroadcastGroupScreen";
import LeadsScreen from "../screens/LeadsScreens";
import BrodcastLogScreen from "../screens/BrodcastLogScreen";

export default function VendorRouter() {
  return (
    <Routes>
      <Route path={DASHBOARD} element={<DashboardScreen />} />
      <Route path={VENDRO_SETTINGS} element={<VendorSettingScreen />} />
      <Route path={VEHICLES} element={<VehicleScreen />} />
      <Route path={BROADCAST_GROUP} element={<BroadcastGroupScreen />} />
      <Route path={BROADCAST_LOG} element={<BrodcastLogScreen />} />
      <Route path={LEADS} element={<LeadsScreen />} />
    </Routes>
  );
}
