// export const COLOR_PRIMARY = "#4DB6AC"
// export const SIDEBAR_COLOR = "#4DB6AC"

// export const COLOR_PRIMARY = "#5C6BC0";
// export const SIDEBAR_COLOR = "#5C6BC0";

// export const COLOR_PRIMARY = "#009688";
// export const SIDEBAR_COLOR = "#009688";

export const COLOR_PRIMARY = "#405FF2";
export const SIDEBAR_COLOR = "#FFFFFF";
