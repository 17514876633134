import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Row, Col, Skeleton } from "antd";
import get from "lodash/get";
import requireAuth from "../../hocs/requireAuth";
import Inventory from "../../components/dashboard/inventory";
import Leads from "../../components/dashboard/leads";
import MostViewedVehicle from "../../components/dashboard/mostViewedvehicle";
import BrodcastingCredits from "../../components/dashboard/brodcastingCredits";
import { fetchDashboardDetails } from "../../redux/actions/dashboardActions";

const DashboardScreen = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [dashboard, setDashboard] = useState(null);

  const getDashboardDetails = () => {
    dispatch(fetchDashboardDetails())
      .then((result) => {
        setDashboard(result);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getDashboardDetails();
  }, []);

  return (
    <div className=" h-full p-3 flex flex-col scrollbar-hidden">
      <div className="pb-8">
        <p className="text-xl font-medium leading-[30px] text-black-1">
          Dashboard
        </p>
      </div>

      <div className=" overflow-y-auto scrollbar-hidden pb-5 min-w-full">
        <Skeleton loading={loading}>
          <Row gutter={32}>
            <Col span={13} className="space-y-4">
              <Inventory inventory={get(dashboard, "inventory")} />
              <Leads leads={get(dashboard, "leads")} />
              <BrodcastingCredits
                brodcastingCredits={get(dashboard, "broadcasting_credits")}
              />
            </Col>
            <Col span={11}>
              <MostViewedVehicle vehicles={get(dashboard, "vehicles")} />
            </Col>
          </Row>
        </Skeleton>
      </div>
    </div>
  );
};

export default requireAuth(DashboardScreen, [1, 2]);
