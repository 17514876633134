import {
  LOGIN,
  CHANGE_PASSWORD,
  FORGOT_PASSWORD,
  VERIFY_FORGOT_PASSWORD,
  CHANGE_FORGOT_PASSWORD,
} from "../../constants/apis";
import * as TYPES from "../../constants/actionTypes";
import get from "lodash/get";

export const login =
  (credentials) =>
  (dispatch, getState, { api }) => {
    return api.post(LOGIN, credentials).then((result) => {
      api.defaults.headers.common.authorization = `Bearer ${get(
        result,
        "token"
      )}`;

      dispatch({
        type: TYPES.LOGIN,
        data: result,
      });
      return result;
    });
  };

export const changePassword =
  (credentials) =>
  (dispatch, getState, { api }) =>
    api
      .put(CHANGE_PASSWORD, credentials)
      .then(() => {})
      .catch((error) => {
        throw error;
      });

export const forgotPassword =
  (payload) =>
  (dispatch, getState, { api }) => {
    return api
      .post(FORGOT_PASSWORD, payload)
      .then((result) => {
        return result;
      })
      .catch((error) => {
        throw error;
      });
  };

export const verifyforgotPassword =
  (payload) =>
  (dispatch, getState, { api }) => {
    return api
      .post(VERIFY_FORGOT_PASSWORD, payload)
      .then((result) => {
        return result;
      })
      .catch((error) => {
        throw error;
      });
  };

export const changeforgotPassword =
  (payload) =>
  (dispatch, getState, { api }) => {
    return api
      .put(CHANGE_FORGOT_PASSWORD, payload)
      .then((result) => {
        return result;
      })
      .catch((error) => {
        throw error;
      });
  };
