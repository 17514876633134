import { VENDORS_ADMIN } from "../../constants/apis";

export const createVendorAdmin =
  (payload, vendorId) =>
  (dispatch, getState, { api }) =>
    api
      .post(VENDORS_ADMIN.replace("{uuid}", vendorId), payload)
      .then((result) => result)
      .catch((error) => {
        throw error;
      });

export const fetchVendorAdmins =
  (vendorId) =>
  (dispatch, getState, { api }) => {
    return api
      .get(VENDORS_ADMIN.replace("{uuid}", vendorId))
      .then((result) => result)
      .catch((error) => {
        throw error;
      });
  };

export const updateVendorAdmin =
  (payload, vendorId, adminId) =>
  (dispatch, getState, { api }) =>
    api
      .put(`${VENDORS_ADMIN.replace("{uuid}", vendorId)}/${adminId}`, payload)
      .then((result) => result)
      .catch((error) => {
        throw error;
      });
