import { Form, Input } from "antd";
import ImageUpload from "../../../imageUpload";
import get from "lodash/get";
import { ProjectFilled } from "@ant-design/icons";
import ImageListUpload from "../../../imageListUpload";

const CompanyDetails = ({
  form,
  logoImage,
  setLogoImage,
  bannerImage,
  setBannerImage,
  setLoading,
}) => {
  return (
    <>
      <div className="flex flex-col ">
        <Form.Item
          label="Logo"
          name="logo"
          rules={[{ required: true, message: "Please select logo image" }]}
        >
          <ImageUpload
            onUploadDone={(imageObj) => {
              setLogoImage(imageObj);
              form.setFieldsValue({
                ...form.getFieldsValue(),
                logo: imageObj,
              });
            }}
            imageUrl={get(logoImage, "url")}
          />
        </Form.Item>
        <Form.Item
          name="banner_images"
          label="Banner"
          rules={[{ required: true, message: "Please select banner image" }]}
        >
          <ImageListUpload
            setLoading={setLoading}
            images={bannerImage}
            edit={false}
            setImages={setBannerImage}
            onUploadDone={(images) =>
              form.setFieldsValue({
                ...form.getFieldsValue(),
                banner_images: images,
              })
            }
          />
        </Form.Item>
      </div>

      <Form.Item
        label="Company Name"
        name="name"
        rules={[{ required: true, message: "Please enter company name" }]}
      >
        <Input
          size="large"
          placeholder="Company Name"
          prefix={<ProjectFilled className="text-icon" />}
        />
      </Form.Item>
      <Form.Item
        name="email"
        label="Email"
        rules={[
          { required: true, message: "Please enter your Email" },
          {
            type: "email",
            message: "Please input valid email",
          },
        ]}
      >
        <Input size="large" placeholder="Email" />
      </Form.Item>
      <Form.Item
        label="Contact"
        name="phone"
        rules={[
          { required: true, message: "Please enter phone number" },
          {
            pattern: /^[6-9]\d{9}$/,
            message: "Please enter valid phone number",
          },
        ]}
      >
        <Input size="large" placeholder="Contact number" />
      </Form.Item>
    </>
  );
};

export default CompanyDetails;
