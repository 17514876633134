import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { SearchOutlined } from "@ant-design/icons";
import { Button, Table, Drawer, Spin, message, Modal, Input } from "antd";
import get from "lodash/get";
import assign from "lodash/assign";
import isNull from "lodash/isNull";
import { vendorColumns } from "../../constants/tableColumns/vendors.js";
import AddVendorForm from "../../components/forms/AddVendorForm/index.js";
import VendorAdmin from "../../components/vendorAdmin/index.js";
import {
  fetchVendors,
  updateVendorStatus,
} from "../../redux/actions/vendorActions.js";
import { UPDATE_VENDOR_STATUS } from "../../constants/message.js";
import VendorSubscription from "../../components/vendorSubscription/index.js";
import requireAuth from "../../hocs/requireAuth.js";

const VendorScreen = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [vendors, setVendors] = useState({
    data: [],
    pagination: null,
  });
  const [drawer, setDrawer] = useState({
    visible: false,
    edit: false,
    data: null,
  });
  const [vendorAdminDrawer, setAdminVendorDrawer] = useState({
    vendor: null,
    visible: false,
  });

  const toggleVendorAdminDrawer = (data) => {
    assign(vendorAdminDrawer, {
      vendor: data,
      visible: !get(vendorAdminDrawer, "visible"),
    });
    setAdminVendorDrawer({ ...vendorAdminDrawer });
  };

  const toggleVendorDrawer = (data = null) => {
    assign(drawer, {
      data: data,
      visible: !get(drawer, "visible"),
      edit: !isNull(data),
    });
    setDrawer({ ...drawer });
  };

  const [subscriptionModal, setSubscriptionModal] = useState({
    visible: false,
    data: null,
  });

  const toggleSubscriptionModal = (data = null) => {
    assign(subscriptionModal, {
      visible: !get(subscriptionModal, "visible"),
      data,
    });
    setSubscriptionModal({ ...subscriptionModal });
  };

  const getVendors = (page = 1) => {
    setLoading(true);
    dispatch(fetchVendors(page, true))
      .then((result) => {
        setLoading(false);
        setVendors({
          data: get(result, "data"),
          pagination: get(result, "meta.pagination"),
        });
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const updateStatus = (id, status) => {
    setLoading(true);
    dispatch(updateVendorStatus({ status: status }, id))
      .then((result) => {
        setLoading(false);
        getVendors();
        message.success(UPDATE_VENDOR_STATUS);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const onSuccess = () => {
    getVendors();
  };

  useEffect(() => {
    getVendors();
  }, []);

  return (
    <>
      <div className="p-3 h-full flex flex-col  ">
        <div className="flex flex-row justify-between pb-8">
          <div>
            <p className="text-xl font-medium leading-[30px] text-black-1">
              Vendor
            </p>
          </div>
          <div>
            <Button
              type="primary"
              onClick={() => setDrawer({ ...drawer, visible: true })}
            >
              Add Vendor
            </Button>
          </div>
        </div>

        <div className="h-full rounded-xl overflow-hidden bg-white flex-1 overflow-y-auto scrollbar-hidden pb-5 min-w-full  ">
          <div className="w-full px-8 py-6">
            <div className="justify-end flex items-center h-full ">
              <Input
                className="w-1/4 flex"
                size="large"
                placeholder="Search Vendor"
                prefix={<SearchOutlined className="mr-2" />}
              />
            </div>
          </div>
          <div className="px-8">
            <Spin spinning={loading}>
              <Table
                columns={vendorColumns(
                  toggleVendorAdminDrawer,
                  toggleVendorDrawer,
                  updateStatus,
                  toggleSubscriptionModal
                )}
                dataSource={get(vendors, "data")}
                pagination={{
                  pageSize: get(vendors, "pagination.per_page", 1),
                  current: get(vendors, "pagination.current_page", 1),
                  total: get(vendors, "pagination.total", 1),
                  onChange: (val) => {
                    getVendors(val);
                  },
                }}
              />
            </Spin>
          </div>
        </div>
        {get(drawer, "visible") && (
          <Drawer
            title={get(drawer, "edit") ? `Update  Vendor` : "Add New Vendor"}
            destroyOnClose
            width={"50%"}
            onClose={() =>
              setDrawer({
                visible: false,
                edit: false,
                data: null,
              })
            }
            open={get(drawer, "visible")}
          >
            <AddVendorForm
              onSuccess={() => {
                onSuccess();
                toggleVendorDrawer();
              }}
              edit={get(drawer, "edit")}
              initialValues={get(drawer, "data")}
              toggleVendorDrawer={toggleVendorDrawer}
            />
          </Drawer>
        )}
        {get(vendorAdminDrawer, "visible") && (
          <Drawer
            title={"Administrators"}
            destroyOnClose
            width={"50%"}
            onClose={() =>
              setAdminVendorDrawer({
                data: null,
                visible: false,
              })
            }
            open={get(vendorAdminDrawer, "visible")}
          >
            <VendorAdmin
              toggleVendorAdminDrawer={toggleVendorAdminDrawer}
              vendor={get(vendorAdminDrawer, "vendor")}
            />
          </Drawer>
        )}

        {get(subscriptionModal, "visible") && (
          <Modal
            title={"Subscription"}
            open={get(subscriptionModal, "visible")}
            destroyOnClose
            width={"60%"}
            footer={false}
            onCancel={toggleSubscriptionModal}
            bodyStyle={{
              height: "80vh",
            }}
            style={{ marginTop: 0, top: 40 }}
          >
            <VendorSubscription vendor={get(subscriptionModal, "data")} />
          </Modal>
        )}
      </div>
    </>
  );
};

export default requireAuth(VendorScreen,[1]);
