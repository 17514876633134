import { Tooltip, Space, Tag } from "antd";
import get from 'lodash/get'
import { EyeTwoTone } from "@ant-design/icons";
import { COLOR_PRIMARY } from "../../constants";

const getStatusTagColor = (val) => {
  switch (val) {
    case 1:
      return "green";
    case 2:
      return "cyan";
    case 3:
      return "blue";
    default:
      return "purple";
  }
};

export const LeadsColumns = ({
    toggleLeadDrawer
}) => [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Email",
    dataIndex: "email",
    key: "email",
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    render: (status) => (
      <Tag color={getStatusTagColor(get(status, "value"))}>
        {get(status, "name")}
      </Tag>
    ),
  },
  {
    title: "Date",
    dataIndex: "created_at_formatted",
    key: "created_at_formatted",
  },
  {
    title: "",
    key: "action",
    align: "center",
    render: (record) => (
      <Space size={15}>
        <Tooltip title="View Lead">
          <EyeTwoTone
            style={{ fontSize: 20 }}
            twoToneColor={COLOR_PRIMARY}
            onClick={() => {
                toggleLeadDrawer(record);
            }}
          />
        </Tooltip>
    
      </Space>
    ),
  },
];
