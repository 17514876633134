import { useEffect, useState } from "react";
import { Button, Modal, Spin, Table, Image } from "antd";
import { useDispatch } from "react-redux";
import get from "lodash/get";
import isNull from "lodash/isNull";
import assign from "lodash/assign";
import capitalize from "lodash/capitalize";
import { VendorAdminColumns } from "../../constants/tableColumns/vendorAdmin";
import VendorAdminForm from "../forms/VendorAdminForm";
import { fetchVendorAdmins } from "../../redux/actions/vendorAdminActions";
import mailIcon from "../../static/images/mail.png";

const VendorAddedSuccess = ({ visible, onClose }) => {
  return (
    <Modal
      open={visible}
      footer={null}
      width={"30%"}
      onCancel={() => {
        onClose();
      }}
    >
      <div className="py-5">
        <div className="w-full flex justify-center">
          <Image src={mailIcon} alt="mail icon" height={80} width={80} />
        </div>
        <div className="flex flex-col justify-center items-center w-full mt-6">
          <p className="text-black text-lg font-semibold">
          Vendor Administrator Addition Successful
          </p>
          <p className="text-sm text-lightGrey mt-2 items-center justify-center text-center">
          An email containing instructions for password generation has been sent to the vendor administrator.
          </p>
        </div>
        <Button
          type="primary"
          className="flex w-full mt-10"
          onClick={() => {
            onClose();
          }}
        >
          Close
        </Button>
      </div>
    </Modal>
  );
};

const VendorAdmin = ({ vendor }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [vendorAdmins, setVendorAdmins] = useState([]);
  const [vendorAddedSuccess, setVendorAddedSuccess] = useState(false);

  const [adminFormModal, setAdminFormModal] = useState({
    visible: false,
    edit: false,
    data: null,
  });

  const toggleAdminFormModal = (data = null) => {
    assign(adminFormModal, {
      visible: !get(adminFormModal, "visible"),
      data,
      edit: !isNull(data),
    });
    setAdminFormModal({ ...adminFormModal });
  };

  const getVendorAdmins = () => {
    setLoading(true);
    dispatch(fetchVendorAdmins(get(vendor, "id")))
      .then((result) => {
        setLoading(false);
        setVendorAdmins(result);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getVendorAdmins();
  }, []);

  return (
    <Spin spinning={loading}>
      <div className="mb-5 justify-between flex">
        <p className="text-black font-semibold text-lg">
          {capitalize(get(vendor, "name"))}
        </p>
        <Button type="primary" key="1" onClick={() => toggleAdminFormModal()}>
          Add Admin
        </Button>
      </div>

      <Table
        rowKey="id"
        className="ant-table-custom-scroll"
        dataSource={vendorAdmins}
        columns={VendorAdminColumns({ toggleAdminFormModal })}
        pagination={false}
      />

      {get(adminFormModal, "visible") && (
        <Modal
          title={
            get(adminFormModal, "edit")
              ? `Update Admin`
              : "Add Admin"
          }
          open={get(adminFormModal, "visible")}
          destroyOnClose
          width={"60%"}
          footer={false}
          onCancel={() => {
            toggleAdminFormModal();
          }}
          
        >
          <VendorAdminForm
            onSuccess={() => {
              getVendorAdmins();
              if (!get(adminFormModal, "edit")) {
                setVendorAddedSuccess(true);
              }
              toggleAdminFormModal();
            }}
            vendors={vendor}
            edit={get(adminFormModal, "edit")}
            initialValues={get(adminFormModal, "data")}
          />
        </Modal>
      )}

      <VendorAddedSuccess
        visible={vendorAddedSuccess}
        onClose={() => {
          setVendorAddedSuccess(false);
        }}
      />
    </Spin>
  );
};
export default VendorAdmin;
