import {
  Spin,
  Button,
  Modal,
  Table,
  message,
  Space,
  Input,
  Select,
} from "antd";
import { SearchOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import assign from "lodash/assign";
import get from "lodash/get";
import isNull from "lodash/isNull";
import map from "lodash/map";
import isEmpty from "lodash/isEmpty";
import debounce from "lodash/debounce";
import requireAuth from "../../hocs/requireAuth";
import { fetchVehicleConfig } from "../../redux/actions/vehiclesAction";
import AddVehicleForm from "../../components/forms/AddVehicleForm";
import { fetchVehicle } from "../../redux/actions/vehiclesAction";
import { vehicleColumns } from "../../constants/tableColumns/vehicles";
import VehicleDetails from "../../components/vehicleDetails";
import BrodcastForm from "../../components/forms/BrodcastForm";
import { markVehicleFeatured, deleteVehicle } from "../../redux/actions/vehiclesAction";
import { UPDATE_VEHICLE_FEATURED, DELETE_VEHICLE_SUCCESS } from "../../constants/message";
import VehicleStatusForm from "../../components/forms/vehicleStatusForm";
import { vehicleStatus } from "../../utilities/vehicle";

const VehicleScreen = () => {
  const dispatch = useDispatch();
  const [modal, contextHolder] = Modal.useModal();
  const [loading, setLoading] = useState(false);
  const [vehicleConfig, setVehicleConfig] = useState({});
  const [vehicleForm, setVehicleForm] = useState({
    visible: false,
    edit: false,
    data: null,
  });



  const [vehicleDetails, setVehicleDetails] = useState({
    visible: false,
    data: null,
  });

  const [brodcastModal, setBrodcastModal] = useState({
    visible  :false,
    data : null
  });
  const [updateStatusModal, setUpdateStatusModal] = useState({
    visible: false,
    data: null,
  });

  const toggleBrodcastModal = (data=null) => {
    assign(brodcastModal,{
      visible : !get(brodcastModal,'visible'),
      data
    })
    setBrodcastModal({...brodcastModal});
  };

  const toggleUpdateStatusModal = (data = null) => {
    assign(updateStatusModal, {
      visible: !get(updateStatusModal, "visible"),
      data,
    });
    setUpdateStatusModal({ ...updateStatusModal });
  };

  const toggleVehicleDetails = (data = null) => {
    assign(vehicleDetails, {
      visible: !get(vehicleDetails, "visible"),
      data,
    });
    setVehicleDetails({ ...vehicleDetails });
  };

  const [vehicles, setVehicles] = useState({
    data: [],
    pagination: null,
    filters: { page: 1, limit: 10 },
  });

  const toggleVehicleForm = (data = null) => {
    assign(vehicleForm, {
      visible: !get(vehicleForm, "visible"),
      edit: !isNull(data),
      data,
    });
    setVehicleForm({ ...vehicleForm });
  };

  const addLabel = (arr) =>
    map(arr, (item) => ({
      ...item,
      label: get(item, "name"),
    }));

  const getVehicles = (page = 1, filters) => {
    const payload = {
      ...get(vehicles, "filters"),
      ...filters,
      page,
    };
    setLoading(true);
    dispatch(fetchVehicle(payload))
      .then((result) => {
        setLoading(false);
        setVehicles({
          data: get(result, "data"),
          pagination: get(result, "meta.pagination"),
          filters: payload,
        });
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const onMarkFeatured = (id, featured) => {
    setLoading(true);
    dispatch(markVehicleFeatured({ is_featured: featured }, id))
      .then((result) => {
        setLoading(false);
        message.success(UPDATE_VEHICLE_FEATURED);
        getVehicles();
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const onDeleteVehicle = (id) => {
    setLoading(true);
    dispatch(deleteVehicle(id))
      .then((result) => {
        setLoading(false);
        message.success(DELETE_VEHICLE_SUCCESS);
        getVehicles();
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const onClickDelete=(id)=>{
    modal.confirm({
      title: 'Are you sure you want to delete?',
      icon: <ExclamationCircleOutlined />,
      content: 'This vehicle will be permanently removed from your website. This action cannot be undone.',
      okText: 'Yes',
      cancelText: 'No',
      onOk:()=>onDeleteVehicle(id)
    });
  }

  const getVehicleConfig = () => {
    setLoading(true);
    dispatch(fetchVehicleConfig())
      .then((result) => {
        setLoading(false);
        setVehicleConfig({
          comfort_conveniences: addLabel(get(result, "comfort_conveniences")),
          body_type: addLabel(get(result, "body_type")),
          brands: addLabel(get(result, "brands")),
          drive_type: addLabel(get(result, "drive_type")),
          fuel_type: addLabel(get(result, "fuel_type")),
          inspections: addLabel(get(result, "inspections")),
          rto_state: addLabel(get(result, "rto_state")),
          safety_checks: addLabel(get(result, "safety_checks")),
          transmission: addLabel(get(result, "transmission")),
          interiors: addLabel(get(result, "interiors")),
          exteriors: addLabel(get(result, "exteriors")),
        });
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const onSearchVehicle = debounce((q) => {
    getVehicles(1, {
      query: q,
    });
  }, 400);

  useEffect(() => {
    getVehicles();
    getVehicleConfig();
  }, []);

  return (
    <>
    {contextHolder}
      <div className="p-3 h-full flex flex-col">
        <div className="flex flex-row justify-between pb-8">
          <div>
            <p className="text-xl font-medium leading-[30px] text-black-1">
              Vehicles
            </p>
          </div>
          <div>
            <Space>
              <Button
                type="primary"
                onClick={() => {
                  toggleVehicleForm();
                }}
              >
                Add Vehicle
              </Button>
            </Space>
          </div>
        </div>

        <div className="h-full rounded-xl overflow-hidden bg-white flex-1 overflow-y-auto scrollbar-hidden pb-5 min-w-full ">
          <div className="w-full px-8 py-6">
            <div className="justify-end flex items-center h-full space-x-5 ">
              <Select
                style={{
                  minWidth: 200,
                }}
                mode="multiple"
                size="middle"
                placeholder="Body Type"
                options={get(vehicleConfig, "body_type")}
                onChange={(val) =>
                  getVehicles(1, { body_type: isEmpty(val) ? undefined : val })
                }
              />
              <Select
                style={{
                  minWidth: 200,
                }}
                mode="multiple"
                size="middle"
                placeholder="Status"
                options={vehicleStatus}
                onChange={(val) =>
                  getVehicles(1, { status: isEmpty(val) ? undefined : val })
                }
              />
              <Input
                style={{
                  width: 200,
                }}
                size="middle"
                onChange={(val) => onSearchVehicle(val.target.value)}
                placeholder="Search Vehicles"
                prefix={<SearchOutlined className="mr-2" />}
              />
            </div>
          </div>
          <div className="px-8 overflow-y-auto">
            <Spin spinning={loading}>
              <Table
                columns={vehicleColumns({
                  toggleVehicleForm,
                  toggleVehicleDetails,
                  toggleBrodcastModal,
                  onMarkFeatured,
                  toggleUpdateStatusModal,
                  onClickDelete
                })}
                dataSource={get(vehicles, "data")}
                pagination={{
                  pageSize: get(vehicles, "pagination.per_page", 1),
                  current: get(vehicles, "pagination.current_page", 1),
                  total: get(vehicles, "pagination.total", 1),
                  onChange: (val) => {
                    getVehicles(val);
                  },
                }}
              />
            </Spin>
          </div>
        </div>
      </div>
      {get(vehicleForm, "visible") && (
        <div className="vehicle-modal">
          <Modal
            destroyOnClose
            footer={null}
            open={get(vehicleForm, "visible")}
            scrollToFirstError
            width={"90%"}
            title=""
            onCancel={toggleVehicleForm}
            maskClosable={false}
            bodyStyle={{
              height: "90vh",
              overflow: "hidden",
              padding: "0px 0px 0px 0px",
            }}
            style={{ marginTop: 0, top: 30 }}
            className="vehicle-modal"
          >
            <div className="flex flex-col h-full ">
              <div className="flex-grow overflow-auto h-full">
                <AddVehicleForm
                  vehicleConfig={vehicleConfig}
                  toggleVehicleForm={toggleVehicleForm}
                  onSuccess={() => getVehicles()}
                  initialValues={get(vehicleForm, "data")}
                  edit={get(vehicleForm, "edit")}
                />
              </div>
            </div>
          </Modal>
        </div>
      )}
      {get(vehicleDetails, "visible") && (
        <Modal
          destroyOnClose
          footer={null}
          open={get(vehicleDetails, "visible")}
          scrollToFirstError
          width={"90%"}
          title={<p className="text-black text-lg">Vehicle Details</p>}
          onCancel={toggleVehicleDetails}
        >
          <VehicleDetails vehicle={get(vehicleDetails, "data")} />
        </Modal>
      )}
      {get(brodcastModal,'visible') && (
        <Modal
          destroyOnClose
          footer={null}
          open={get(brodcastModal,'visible')}
          scrollToFirstError
          width={"40%"}
          title={<p className="text-black text-lg">Brodcast Message</p>}
          onCancel={toggleBrodcastModal}
        >
          <BrodcastForm toggleBrodcastModal={toggleBrodcastModal} vehicle = {get(brodcastModal,'data')} />
        </Modal>
      )}

      {get(updateStatusModal, "visible") && (
        <Modal
          destroyOnClose
          footer={null}
          open={get(updateStatusModal, "visible")}
          scrollToFirstError
          width={"30%"}
          title={<p className="text-black text-lg">Update Status</p>}
          onCancel={toggleUpdateStatusModal}
        >
          <VehicleStatusForm
            toggleUpdateStatusModal={toggleUpdateStatusModal}
            data={get(updateStatusModal, "data")}
            onSuccess={() => getVehicles()}
          />
        </Modal>
      )}
    </>
  );
};

export default requireAuth(VehicleScreen, [2]);
