import { Row, Col, Form, Input, InputNumber, Select } from "antd";
import get from "lodash/get";

const VehicleEngineInfo = ({ vehicleConfig }) => {
  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  return (
    <>
      <Row>
        <Col span={11}>
          <Form.Item
            label="Transmission"
            name={["engine_config", "transmission"]}
            rules={[{ required: true, message: "Please select transmission" }]}
          >
            <Select
              showSearch
              filterOption={filterOption}
              size="large"
              onChange={() => {}}
              placeholder="Transmission"
              options={get(vehicleConfig, "transmission")}
            />
          </Form.Item>
        </Col>
        <Col span={2} />
        <Col span={11}>
          <Form.Item
            label={
              <p>
                Engine Power <span className="text-lightGrey">(In BHP)</span>
              </p>
            }
            name={["engine_config", "engine_power"]}
            rules={[{ required: true, message: "Please enter engine powner" }]}
          >
            <Input size="large" placeholder="Engine Power" />
          </Form.Item>
        </Col>
      </Row>

      <Row>
        <Col span={11}>
          <Form.Item
            label={
              <p>
                Capacity <span className="text-lightGrey">(In CC)</span>
              </p>
            }
            name={["engine_config", "capacity"]}
            rules={[{ required: true, message: "Please enter capacity" }]}
          >
            <InputNumber
              className="flex w-full"
              size="large"
              placeholder="Capacity"
            />
          </Form.Item>
        </Col>
        <Col span={2} />
        <Col span={11}>
          <Form.Item
            label="Drive Type"
            name={["engine_config", "drive_type"]}
            rules={[{ required: true, message: "Please select drive type" }]}
          >
            <Select
              showSearch
              filterOption={filterOption}
              size="large"
              onChange={() => {}}
              placeholder="Drive Type"
              options={get(vehicleConfig, "drive_type")}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={11}>
          <Form.Item
            label={<p>Milage <span className="text-lightGrey">(In Km/ltr)</span> </p>}
            name={["engine_config", "milage"]}
            rules={[{ required: true, message: "Please enter milage" }]}
          >
            <InputNumber
              className="flex w-full"
              size="large"
              placeholder="Milage"
            />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};

export default VehicleEngineInfo;
