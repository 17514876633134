import get from "lodash/get";

export const FilterTag = ({ name }) => {
  return (
    <div className="bg-[#fafafa] space p-2 space-x-2 flex flex-row w-fit rounded-lg ">
      <p className="text-black text-xs font-medium">{name}</p>
    </div>
  );
};

export const Description = ({ index, item }) => {
  return (
    <div
      key={index}
      className={`w-full flex flex-row px-4 py-2.5 ${
        index % 2 === 0 && "bg-grey-1"
      }`}
    >
      <div className="basis-2/5  w-full">
        <p className=" text-sm font-normal text-black">{get(item, "label")}</p>
      </div>
      <div className="basis-3/5   w-full">{get(item, "value")}</div>
    </div>
  );
};
