import { Form, Input } from "antd";
import { useSelector } from "react-redux";
import get from "lodash/get";
import {
  FacebookOutlined,
  InstagramOutlined,
  YoutubeOutlined,
  ProjectFilled,
  TwitterOutlined,
} from "@ant-design/icons";

const WebsiteDetails = () => {
  const isAdmin =
    useSelector((state) => get(state, "auth.user.role.value")) === 1;
  return (
    <>
      <Form.Item
        label="Website Domain Url"
        name={["website_details", "domain"]}
        rules={[{ type: "url", message: "Please enter valid url" }]}
      >
        <Input size="large" placeholder="Website Domain" disabled={!isAdmin} />
      </Form.Item>

      <Form.Item
        label="Tagline"
        name={["website_details", "tag_line"]}
        rules={[{ required: true, message: "Please enter tagline" }]}
      >
        <Input size="large" placeholder="Tagliine" />
      </Form.Item>
      <Form.Item
        label="Description"
        name={["website_details", "description"]}
        rules={[{ required: true, message: "Please enter description" }]}
      >
        <Input.TextArea
          size="large"
          placeholder="Description"
          prefix={<ProjectFilled className="text-icon" />}
        />
      </Form.Item>
      <Form.Item
        name={["website_details", "facebook_url"]}
        label="Facebook"
        rules={[{ type: "url", message: "Please enter valid url" }]}
      >
        <Input
          size="large"
          placeholder="Facebook"
          prefix={<FacebookOutlined className="text-icon" />}
        />
      </Form.Item>

      <Form.Item
        name={["website_details", "instagram_url"]}
        label="Instagram"
        rules={[{ type: "url", message: "Please enter valid url" }]}
      >
        <Input
          size="large"
          placeholder="Instagram"
          prefix={<InstagramOutlined className="text-icon" />}
        />
      </Form.Item>
      <Form.Item
        name={["website_details", "twitter_url"]}
        label="Twitter"
        rules={[{ type: "url", message: "Please enter valid url" }]}
      >
        <Input
          size="large"
          placeholder="Twitter"
          prefix={<TwitterOutlined className="text-icon" />}
        />
      </Form.Item>

      <Form.Item
        name={["website_details", "youtube_url"]}
        label="Youtube"
        placeholder="Youtube"
        rules={[{ type: "url", message: "Please enter valid url" }]}
      >
        <Input
          size="large"
          prefix={<YoutubeOutlined className="text-icon" />}
        />
      </Form.Item>
    </>
  );
};

export default WebsiteDetails;
