import { useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Divider, Form, Steps, Spin, message, Affix } from "antd";
import get from "lodash/get";
import CompanyDetails from "./companyDetails";
import AddressDetails from "./addressDetails";
import WebsiteDetails from "./websiteDetails";
import { getVendorPayload } from "../../../utilities/vendor";
import {
  createVendor,
  updateVendor,
} from "../../../redux/actions/vendorActions";
import {
  CREATE_VENDOR_SUCCESS,
  UPDATE_VENDOR_SUCCESS,
} from "../../../constants/message";

const AddVendorForm = (props) => {
  const { edit, initialValues, onSuccess, toggleVendorDrawer } = props;
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [current, setCurrent] = useState(0);
  const [formvalues, setFormValues] = useState({});
  const [address, setAddress] = useState(
    edit ? get(initialValues, "address") : null
  );
  const [logoImage, setLogoImage] = useState(
    edit ? get(initialValues, "logo") : { key: null, url: null }
  );
  const [bannerImage, setBannerImage] = useState(
    edit ? get(initialValues, "banner_images") : []
  );

  const steps = [
    {
      title: "Company Details",
    },
    {
      title: "Address",
    },
    {
      title: "Website details",
    },
  ];

  const onCreateVendor = (payload) => {
    dispatch(createVendor(payload))
      .then(() => {
        setLoading(false);
        message.success(CREATE_VENDOR_SUCCESS);
        onSuccess();
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const onUpdateVendor = (payload) => {
    dispatch(updateVendor(payload, get(initialValues, "id")))
      .then(() => {
        setLoading(false);
        message.success(UPDATE_VENDOR_SUCCESS);
        onSuccess();
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const onSubmitForm = (values) => {
    const payload = getVendorPayload({ ...formvalues, ...values, banner_images : bannerImage });
    setLoading(true);
    edit ? onUpdateVendor(payload) : onCreateVendor(payload);
  };

  const onNext = async () => {
    if (current === 2) return;
    try {
      await form.validateFields();
      setFormValues({
        ...formvalues,
        ...form.getFieldsValue(),
      });
      setCurrent(current + 1);
    } catch (error) {}
  };

  const onBack = () => {
    setCurrent(current - 1);
  };

  return (
    <div className="flex flex-col h-full ">
      {loading && <Spin spinning={loading} className="spinner-overlay" />}
      <div className=" h-full overflow-y-auto scrollbar-hidden">
        <Form
          form={form}
          layout="vertical"
          scrollToFirstError
          onFinish={onSubmitForm}
          onFinishFailed={() => {}}
          initialValues={edit ? initialValues : {}}
          className="flex flex-col h-full "
        >
          <Steps
            current={current}
            items={steps}
            onChange={(val) => val < current && setCurrent(val)}
          />

          <Divider />

          <div key="full" className="overflow-y-auto scrollbar-hidden flex-grow flex-col min-h-full flex">
            {current === 0 && (
              <CompanyDetails
                form={form}
                initialValues={initialValues}
                edit={edit}
                logoImage={logoImage}
                setLogoImage={setLogoImage}
                bannerImage={bannerImage}
                setBannerImage={setBannerImage}
                setLoading={setLoading}
              />
            )}
            {current === 1 && (
              <AddressDetails
                form={form}
                initialValues={initialValues}
                edit={edit}
                address={address}
                setAddress={setAddress}
              />
            )}

            {current === 2 && (
              <WebsiteDetails initialValues={initialValues} edit={edit} />
            )}
          </div>
        </Form>
      </div>
      <Affix offsetBottom={10}>
        <div className=" w-full flex-row flex space-x-5   ">
          <Button
            block
            onClick={() => {
              current === 0 ? toggleVendorDrawer() : onBack();
            }}
          >
            {current === 0 ? "Cancel" : "Back"}
          </Button>
          {current === 0 || current === 1 ? (
            <Button
              block
              type="primary"
              onClick={() => {
                onNext();
              }}
            >
              {"Next"}
            </Button>
          ) : (
            <Button block type="primary" onClick={() => form.submit()}>
              {edit ? "Update" : "Save"}
            </Button>
          )}
        </div>
      </Affix>
    </div>
  );
};

export default AddVendorForm;
