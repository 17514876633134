import { Routes, Route } from "react-router-dom";
import { DASHBOARD, VENDROS, SUBSCRIPTION } from "../constants/routes";
import DashboardScreen from "../screens/DashboardScreen";
import VendorScreen from "../screens/VendorScreen";
import SubscriptionScreen from "../screens/SubscriptionScreen";

export default function AdminRouter() {
  return (
    <Routes>
      {/* <Route path={DASHBOARD} element={<DashboardScreen />} /> */}
      <Route path={VENDROS} element={<VendorScreen />} />
      <Route path={SUBSCRIPTION} element={<SubscriptionScreen />} />
    </Routes>
  );
}
