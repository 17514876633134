import { Tooltip, Space, Tag } from "antd";
import { EditTwoTone, DeleteTwoTone, EyeTwoTone } from "@ant-design/icons";
import { COLOR_PRIMARY } from "../../constants";
import get from "lodash/get";
import upperCase from 'lodash/upperCase'

const getTagColor = (val) => {
  switch (val) {
    case 0:
      return "purple";
    case 1:
      return "cyan";
    default:
      return "purple";
  }
};

export const BrodcastGroupColumns = ({
  toggleBrodcastGroupForm,
  toggleEnteriesModal,
  onClickDelete,
}) => [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Type",
    dataIndex: "type",
    key: "type",
    render: (type) => (
      <Tag color={getTagColor(get(type, "value"))}>{upperCase(get(type, "name"))}</Tag>
    ),
  },
  {
    title: "Enteries",
    dataIndex: "enteries",
    key: "type",
    render: (enteries) => (
      <div className=" flex flex-row space-x-2 items-center ">
        <p className="w-10 flex ">{enteries.length} </p>
        <Tooltip title="View all enteries">
          <EyeTwoTone
            style={{ fontSize: 20 }}
            twoToneColor={COLOR_PRIMARY}
            onClick={() => {
              toggleEnteriesModal(enteries);
            }}
          />
        </Tooltip>
      </div>
    ),
  },
  {
    title: "",
    key: "action",
    align: "center",
    render: (record) => (
      <Space size={15}>
        <Tooltip title="Edit Group">
          <EditTwoTone
            style={{ fontSize: 20 }}
            twoToneColor={COLOR_PRIMARY}
            onClick={() => {
              toggleBrodcastGroupForm(record);
            }}
          />
        </Tooltip>
        <Tooltip title="Delete Group">
          <DeleteTwoTone
            style={{ fontSize: 20 }}
            twoToneColor={COLOR_PRIMARY}
            onClick={() => {
              onClickDelete(record);
            }}
          />
        </Tooltip>
      </Space>
    ),
  },
];
