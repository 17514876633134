import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import get from "lodash/get";
import LoginForm from "../../components/forms/LoginForm";
import { DASHBOARD, VENDROS } from "../../constants/routes";
import LandingHeader from "../../components/landingHeader";

const LoginScreen = () => {
  const store = useSelector((state) => get(state, "auth"));
  const isLoggedIn = get(store, "isLoggedIn");
  const isSuperAdmin = get(store, "user.role.value") === 1;
  const navigate = useNavigate();

  useEffect(() => {
    if (isLoggedIn) {
      navigate(isSuperAdmin ? VENDROS : DASHBOARD, { replace: true });
    }
  }, [isLoggedIn, navigate]);

  return (
    <div className="w-full justify-center items-center bg-grey-8 login-grid-lines h-full bg-[#F7F8F9] ">
      <LandingHeader />
      <div className="  justify-center flex mt-20 sm:p-0 p-5  ">
        <div className="w-[423px] bg-white shadow rounded-xl ">
          <div className="sm:p-10 p-5 ">
            <div className="space-y-2 pb-10">
              <p className="text-black text-xl leading-[30px] font-medium text-center">
                Login with Autovend
              </p>
              <p className="text-[#8B8D97] text-sm font-normal text-center">
                Login to your account with your credentials
              </p>
            </div>
            <LoginForm />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginScreen;
