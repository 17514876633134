import {
  VEHICLES,
  VEHICLES_STATUS,
  VEHICLES_FEATURED,
} from "../../constants/apis";
import { getUrl } from "../../utilities/helpers";

export const fetchVehicleConfig =
  (payload) =>
  (dispatch, getState, { api }) =>
    api
      .get(`${VEHICLES}/config`)
      .then((result) => result)
      .catch((error) => {
        throw error;
      });

export const createVehicle =
  (payload) =>
  (dispatch, getState, { api }) =>
    api
      .post(VEHICLES, payload)
      .then((result) => result)
      .catch((error) => {
        throw error;
      });

export const fetchVehicle =
  (filters) =>
  (dispatch, getState, { api }) => {
    const url = getUrl(VEHICLES, filters);
    return api
      .get(url)
      .then((result) => result)
      .catch((error) => {
        throw error;
      });
  };

export const updateVehicle =
  (payload, uuid) =>
  (dispatch, getState, { api }) =>
    api
      .put(`${VEHICLES}/${uuid}`, payload)
      .then((result) => result)
      .catch((error) => {
        throw error;
      });

export const updateVehicleStatus =
  (payload, uuid) =>
  (dispatch, getState, { api }) =>
    api
      .put(`${VEHICLES_STATUS.replace("{uuid}", uuid)}`, payload)
      .then((result) => result)
      .catch((error) => {
        throw error;
      });

export const markVehicleFeatured =
  (payload, uuid) =>
  (dispatch, getState, { api }) =>
    api
      .put(`${VEHICLES_FEATURED.replace("{uuid}", uuid)}`, payload)
      .then((result) => result)
      .catch((error) => {
        throw error;
      });

export const deleteVehicle =
  (uuid) =>
  (dispatch, getState, { api }) =>
    api
      .delete(`${VEHICLES}/${uuid}`)
      .then((result) => result)
      .catch((error) => {
        throw error;
      });
