export const LOGIN = "/login";
export const CHANGE_PASSWORD = "/change-password";
export const IMAGE_UPLOAD = `${process.env.REACT_APP_API_HOST}/media`;
export const VENDORS = "vendors";
export const VENDORS_ADMIN = "vendors/{uuid}/admins";
export const VENDORS_STATUS = "vendors/{uuid}/status";
export const VEHICLES = "vehicles";
export const VEHICLES_STATUS = "vehicles/{uuid}/status";
export const VEHICLES_FEATURED = "vehicles/{uuid}/featured";
export const VENDOR_DETAILS = "vendors-details";
export const BRODCAST_GROUPS = "broadcast-groups";
export const LEADS = "leads";
export const FORGOT_PASSWORD = "forgot-password";
export const VERIFY_FORGOT_PASSWORD = "/verify-forgot-password";
export const CHANGE_FORGOT_PASSWORD = "/change-forgot-password";
export const SUBSCRIPTIONS = "subscriptions";
export const SUBSCRIBE_SUBSCRIPTION =
  "/vendors/{vendorId}/subscriptions/{subscriptionId}";
export const VENDOR_SUBSCRIPTIONS = "/vendors/{uuid}/subscriptions";
export const DASHBOARD = "/dashboard";
export const GENERATE_BRODCAST = "/vehicles/{vehicleId}/broadcast/{groupId}";
export const BRODCAST_LOGS = "/broadcast/logs";