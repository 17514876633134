import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Card, Button, Form, Input, message, Image, Modal, Spin } from "antd";
import get from "lodash/get";
import capitalize from "lodash/capitalize";
import { changePassword } from "../../redux/actions/authActions";
import { CHANGE_PASSWORD_SUCCESS } from "../../constants/message";
import { COLOR_PRIMARY } from "../../constants";
import userIcon from "../../static/icons/menu/personlisation.svg";
import phoneIcon from "../../static/icons/phone.svg";
import mailIcon from "../../static/icons/mail.svg";

const ProfileScreen = () => {
  const user = useSelector((state) => get(state, "auth.user"));
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [passwordModal, setPasswordModal] = useState(false);
  const togglePasswordModal = () => {
    setPasswordModal(!passwordModal);
  };

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(undefined);

  const onFinish = (values) => {
    setLoading(true);
    dispatch(changePassword(values))
      .then(() => {
        setLoading(false);
        message.success(CHANGE_PASSWORD_SUCCESS);
        form.resetFields();
        togglePasswordModal();
      })
      .catch((error) => {
        setError(get(error, "message"));
        setLoading(false);
      });
  };

  const onFinishFailed = () => {};

  return (
    <>
      <div className=" h-full p-3 flex flex-col scrollbar-hidden">
        <div className="pb-8">
          <p className="text-xl font-medium leading-[30px] text-black-1">
            Profile
          </p>
        </div>
        <div className=" overflow-y-auto scrollbar-hidden pb-5 w-2/3 space-y-10">
          <Card bordered={false} style={{ borderRadius: 12 }}>
            <p className="text-black-1 text-lg font-semibold">My Profile</p>
            <div className="pt-5 space-y-2  ">
              <div className="flex flex-row space-x-1 items-center">
                <div className="w-10 h-fit justify-center flex">
                  <Image src={userIcon} alt="userIcon" />
                </div>
                <p className="text-black text-sm opacity-45">
                  {capitalize(get(user, "name"))}
                </p>
              </div>
              <div className="flex flex-row space-x-1 items-center">
                <div className="w-10 h-fit justify-center flex">
                  <Image src={mailIcon} alt="userIcon" width={20} />
                </div>
                <p className="text-black text-sm opacity-45 pb-1">
                  {get(user, "email")}
                </p>
              </div>
              <div className="flex flex-row space-x-1 items-center">
                <div className="w-10 h-fit justify-center flex">
                  <Image src={phoneIcon} alt="userIcon" />
                </div>
                <p className="text-black text-sm opacity-45">
                  {get(user, "phone")}
                </p>
              </div>
            </div>
          </Card>
          <Card bordered={false} style={{ borderRadius: 12 }}>
            <p className="text-[22px] text-black font-bold">Password</p>
            <div className="flex justify-between items-center">
              <div>
                <p className="text-black opacity-45 text-sm">User password</p>
              </div>
              <Button
                onClick={togglePasswordModal}
                style={{
                  borderColor: COLOR_PRIMARY,
                  borderRadius: 8,
                  height: 35,
                }}
                className="custom-ant-btn"
                htmlType="submit"
              >
                <p className="text-primary text-xs font-normal hover-white">
                  Change Password
                </p>
              </Button>
            </div>
          </Card>
        </div>
      </div>
      <Modal
        title="Update Password"
        open={passwordModal}
        okText="Update"
        footer={null}
        onCancel={togglePasswordModal}
      >
        <Spin spinning={loading}>
          <Form
            form={form}
            layout="vertical"
            scrollToFirstError
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <div className="pt-5">
              <Form.Item
                label="Current Password"
                name="current_password"
                rules={[
                  {
                    required: true,
                    message: "Please enter current password!",
                  },
                ]}
                validateStatus={error ? "error" : ""}
                help={error}
              >
                <Input.Password
                  placeholder="Password"
                  onChange={() => error && setError(undefined)}
                />
              </Form.Item>
            </div>
            <div>
              <Form.Item
                label="New Password"
                name="new_password"
                rules={[
                  {
                    required: true,
                    message: "Please enter new password!",
                  },
                ]}
              >
                <Input.Password placeholder="New Password" />
              </Form.Item>
            </div>
            <div >
              <Form.Item
                label="Confirm Password"
                name="confirm_password"
                rules={[
                  {
                    required: true,
                    message: "Please confirm your password!",
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("new_password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error(
                          "The two passwords that you entered do not match!"
                        )
                      );
                    },
                  }),
                ]}
              >
                <Input.Password placeholder="Confirm Password" />
              </Form.Item>
            </div>
            <Form.Item className="login-form-item">
              <Button block type="primary" htmlType="submit">
                Change Password
              </Button>
            </Form.Item>
          </Form>
        </Spin>
      </Modal>
    </>
  );
};

export default ProfileScreen;
