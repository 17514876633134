import { VENDORS, VENDORS_STATUS, VENDOR_DETAILS } from "../../constants/apis";
import { getUrl } from "../../utilities/helpers";

export const createVendor =
  (payload) =>
  (dispatch, getState, { api }) =>
    api
      .post(VENDORS, payload)
      .then((result) => result)
      .catch((error) => {
        throw error;
      });

export const fetchVendors =
  (page = 1, isPagination = false) =>
  (dispatch, getState, { api }) => {
    const url = isPagination
      ? getUrl(VENDORS, {
          page,
          limit: 10,
          isPagination,
        })
      : VENDORS;

    return api
      .get(url)
      .then((result) => result)
      .catch((error) => {
        throw error;
      });
  };

export const updateVendor =
  (payload, uuid) =>
  (dispatch, getState, { api }) =>
    api
      .put(`${VENDORS}/${uuid}`, payload)
      .then((result) => result)
      .catch((error) => {
        throw error;
      });

export const updateVendorStatus =
  (payload, uuid) =>
  (dispatch, getState, { api }) =>
    api
      .put(`${VENDORS_STATUS.replace("{uuid}", uuid)}`, payload)
      .then((result) => result)
      .catch((error) => {
        throw error;
      });

export const fetchVendorDetails =
  (payload, uuid) =>
  (dispatch, getState, { api }) =>
    api
      .get(VENDOR_DETAILS)
      .then((result) => result)
      .catch((error) => {
        throw error;
      });
