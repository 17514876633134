import { Routes, Route } from "react-router-dom";
import { LOGIN, PROFILE, CHANGE_PASSWORD, FORGOT_PASSWORD } from "../constants/routes";

import LoginScreen from "../screens/LoginScreen";
import ProfileScreen from "../screens/ProfileScreen";
import ChangePasswordScreen from "../screens/ChangePasswordScreen";
import ForgotPasswordScreen from "../screens/ForgotPasswordScreen";

const AuthRouter = () => {
  return (
    <Routes>
      <Route path={LOGIN} element={<LoginScreen />} />
      <Route path={FORGOT_PASSWORD} element={<ForgotPasswordScreen />} />
      <Route path={PROFILE} element={<ProfileScreen />} />
      <Route path="/" element={<LoginScreen />} />
      <Route path={CHANGE_PASSWORD} element={<ChangePasswordScreen />} />
    </Routes>
  );
};

export default AuthRouter;
