import { Row, Form, Checkbox, List, Col } from "antd";
import get from "lodash/get";

const InteriorExterior = ({ vehicleConfig }) => {
  const interiors = get(vehicleConfig, "interiors", []);
  const exteriors = get(vehicleConfig, "exteriors", []);
  return (
    <Row>
      <Col span={11} className="overflow-hidden">
        {" "}
        <Form.Item
          label={<p className="font-medium text-base">Interior</p>}
          name={"interiors"}
        >
          <Checkbox.Group className="flex flex-col w-full space-y-3">
            <List
              dataSource={interiors}
              renderItem={(item) => (
                <List.Item>
                  <Checkbox value={item.value} className="custom-checkbox">
                    {item.label}
                  </Checkbox>
                </List.Item>
              )}
              className=" max-h-[460px] 2xl:max-h-[620px] overflow-y-auto"
            />
          </Checkbox.Group>
        </Form.Item>
      </Col>
      <Col span={2} />
      <Col span={11} className="overflow-hidden">
        <Form.Item
          label={<p className="font-medium text-base">Exterior</p>}
          name={"exteriors"}
        >
          <Checkbox.Group className="flex flex-col  w-screen space-y-3">
            <List
              dataSource={exteriors}
              renderItem={(item) => (
                <List.Item>
                  <Checkbox value={item.value} className="custom-checkbox">
                    {item.label}
                  </Checkbox>
                </List.Item>
              )}
              className=" max-h-[460px] 2xl:max-h-[620px] overflow-y-auto"
            />
          </Checkbox.Group>
        </Form.Item>
      </Col>
    </Row>
  );
};

export default InteriorExterior;
