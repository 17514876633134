import { useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Input, Form, Spin, Image } from "antd";
import { useNavigate } from "react-router-dom";
import {
  DASHBOARD,
  FORGOT_PASSWORD,
  CONTACT_US,
  VENDROS,
} from "../../../constants/routes";
import { login } from "../../../redux/actions/authActions";
import emailIcon from "../../../static/icons/message.svg";
import lockIcon from "../../../static/icons/lock.svg";

const LoginForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const onFinish = (values) => {
    setLoading(true);
    dispatch(login(values))
      .then((result) => {
        setLoading(false);
        navigate(get(result, "role.value") === 1 ? VENDROS : DASHBOARD, {
          replace: true,
        });
      })
      .catch(() => {
        setLoading(false);
      });
  };

  return (
    <Spin spinning={loading}>
      <div className="flex flex-col ">
        <Form
          className="form-input"
          name="login"
          layout="vertical"
          requiredMark={false}
          size="large"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={() => {}}
        >
          <Form.Item
            name="email"
            label=""
            rules={[
              { required: true, message: "Please input your Email!" },
              {
                type: "email",
                message: "Please input valid email",
              },
            ]}
          >
            <Input
              size="large"
              placeholder="Email"
              prefix={<Image src={emailIcon} alt="email" />}
              className="mr-10"
            />
          </Form.Item>

          <Form.Item
            name="password"
            label=""
            className="mt-10"
            rules={[{ required: true, message: "Please input your password!" }]}
            extra={
              <div className="flex justify-end">
                <Button
                  onClick={() => {
                    navigate(FORGOT_PASSWORD);
                  }}
                  type="link"
                >
                  <p className="text-primary font-normal text-sm ">
                    Recover password
                  </p>
                </Button>
              </div>
            }
          >
            <Input.Password
              size="large"
              placeholder="Password"
              prefix={<Image src={lockIcon} alt="lock" />}
              className="mr-2"
            />
          </Form.Item>
          <div className=" flex items-center w-full  justify-center">
            <div className="w-[180px]">
              <Button
                htmlType="submit"
                type="primary"
                className="w-full mt-3"
                style={{ height: 44, width: 180, borderRadius: 12 }}
              >
                Login
              </Button>
            </div>
          </div>
        </Form>
        <div className="pt-10">
          <p className="text-[#8B8D97] text-sm font-normal text-center">
            Don&apos;t have an account?{" "}
            <span
              className="text-primary hover:cursor-pointer"
              onClick={() => {
                window.open(
                  `${process.env.REACT_APP_WEBSITE_HOST}${CONTACT_US}`,
                  "_self"
                );
              }}
            >
              Register with us
            </span>
          </p>
        </div>
      </div>
    </Spin>
  );
};

export default LoginForm;
