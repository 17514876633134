import map from "lodash/map";
import get from "lodash/get";

export const vendors = [
  {
    name: "vikas motors",
    email: "vikasmotors@gmail.com",
    logo: "",
    banner: "",
    address: "9 parnell streat , Noida, Uttarpradesh",
  },
];

export const getUrl = (apiPath, params = null) => {
  let paramString = "";
  let firstParam = true;
  if (params) {
    paramString += "?";
    map(params, (value, key) => {
      if (value) {
        if (firstParam) {
          paramString += `${key}=${value}`;
          firstParam = false;
        } else {
          paramString += `&${key}=${value}`;
        }
      }
    });
  }
  return `${apiPath}${paramString}`;
};

export const getAddressString = (addressObj) => {
  const add1 = get(addressObj, "address_1") ? get(addressObj, "address_1") : "";
  const add2 = get(addressObj, "address_2")
    ? `, ${get(addressObj, "address_2")}`
    : "";
  const postalCode = get(addressObj, "postal_code")
    ? `, ${get(addressObj, "postal_code")}`
    : "";
  const suburb = get(addressObj, "suburb")
    ? `, ${get(addressObj, "suburb")}`
    : "";
  const state = get(addressObj, "state") ? `, ${get(addressObj, "state")}` : "";
  const country = get(addressObj, "country")
    ? `, ${get(addressObj, "country")}`
    : "";

  return `${add1}${add2}${suburb}${postalCode}${state}${country}`;
};

export const scrollContainer = (scrollOffset, containerRef) => {
  const container = containerRef.current;
  const startPosition = container.scrollLeft;
  const duration = 300; // milliseconds

  const startTime = performance.now();

  const easeInOutQuad = (t) => (t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t);

  const animateScroll = (currentTime) => {
    const elapsedTime = currentTime - startTime;
    const scrollProgress = Math.min(1, elapsedTime / duration);
    const easedProgress = easeInOutQuad(scrollProgress);
    container.scrollLeft = startPosition + scrollOffset * easedProgress;

    if (elapsedTime < duration) {
      requestAnimationFrame(animateScroll);
    }
  };

  requestAnimationFrame(animateScroll);
};

export const getOrdinalSuffix = (val) => {
  const j = val % 10;
  const k = val % 100;
  if (j === 1 && k !== 11) {
    return `${val}st`;
  }
  if (j === 2 && k !== 12) {
    return `${val}nd`;
  }
  if (j === 3 && k !== 13) {
    return `${val}rd`;
  }
  return `${val}th`;
};

export const numberToWords = (num) => {
  if (num < 1000) return num;
  if (num >= 100000) {
    const lakhPart = num / 100000;
    return `${lakhPart.toFixed(2)} Lakh`;
  } else if (num >= 1000) {
    const thousandPart = num / 1000;
    return `${thousandPart.toFixed(num % 1000 === 0 ? 0 : 2)} K`;
  } else {
    const integerPart = Math.floor(num);
    const decimalPart = num - integerPart;
    const integerWords = numberToWords(integerPart);
    const decimalWords = decimalPart
      ? `${numberToWords(Math.floor(decimalPart * 100))} Hundredth`
      : "";
    return `${integerWords} ${decimalWords}`.trim();
  }
};
