
import { DASHBOARD } from "../../constants/apis";

export const fetchDashboardDetails =
  (params={}) =>
  (dispatch, getState, { api }) => {
    return api
      .get(DASHBOARD)
      .then((result) => result)
      .catch((error) => {
        throw error;
      });
  };