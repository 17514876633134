import { Spin, Form, Input, Select, message, Button, Tag, Row, Col } from "antd";
import { useState } from "react";
import { useDispatch } from "react-redux";
import get from "lodash/get";
import split from "lodash/split";
import trim from "lodash/trim";
import map from "lodash/map";
import remove from "lodash/remove";
import find from "lodash/find";
import includes from "lodash/includes";

import { groupTypeOptions } from "../../../utilities/brodcastGroup";
import {
  createBrodcastGroup,
  updateBrodcastGroup,
} from "../../../redux/actions/brodcastGroupActions";
import {
  CREATE_GROUP_SUCCESS,
  UPDATE_GROUP_SUCCESS,
} from "../../../constants/message";

const { TextArea } = Input;

const AddBrodcastGroup = ({
  toggleBrodcastGroupForm,
  onSuccess,
  edit,
  initialValues,
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const [type, setType] = useState(
    edit ? get(initialValues, "type.value") : null
  );
  const [validateEnteries, setValidateEnteries] = useState(
    edit
      ? map(get(initialValues, "enteries"), (item) => ({
          error: false,
          value: item,
        }))
      : []
  );
  const [disableValidate, setDisableValidate] = useState(true);
  const [enteries, setEnteries] = useState("");
  const [isError, setIsError] = useState(
    includes(validateEnteries, (item) => get(item, "error") === true)
  );

  const onCreateBrodcastGroup = (payload) => {
    setLoading(true);
    dispatch(createBrodcastGroup(payload))
      .then(() => {
        setLoading(false);
        message.success(CREATE_GROUP_SUCCESS);
        toggleBrodcastGroupForm();
        onSuccess();
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const onUpdateBrodcastGroup = (payload, id) => {
    setLoading(true);
    dispatch(updateBrodcastGroup(payload, id))
      .then(() => {
        setLoading(false);
        message.success(UPDATE_GROUP_SUCCESS);
        toggleBrodcastGroupForm();
        onSuccess();
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const onValidateEnteries = () => {
    const mobileNumberRegex = /^[6-9]\d{9}$/;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const regax = type === 0 ? emailRegex : mobileNumberRegex;
    const enteries = split(form.getFieldValue("enteries"), ",");

    const updatedEntries = map(enteries, (item) =>
      regax.test(trim(item))
        ? { error: false, value: trim(item) }
        : { error: true, value: trim(item) }
    );

    setIsError(!!find(updatedEntries, (item) => get(item, "error") === true));
    setValidateEnteries([...updatedEntries]);

    setDisableValidate(true);
  };

  const onRemoveTag = (removeItem, index) => {
    const upadtedValidateEntries = [...validateEnteries];
    remove(
      upadtedValidateEntries,
      (item) => get(item, "value") === get(removeItem, "value")
    );
    form.setFieldsValue({
      ...form.getFieldsValue(),
      enteries: map(upadtedValidateEntries, "value").join(","),
    });
    setIsError(
      includes(validateEnteries, (item) => get(item, "error") === true)
    );
    setValidateEnteries(upadtedValidateEntries);
    if (upadtedValidateEntries.length === 0) setDisableValidate(true);
  };

  const onFinish = (values) => {
    const payload = {
      ...values,
      enteries: map(validateEnteries, (item) => get(item, "value")),
    };
    edit
      ? onUpdateBrodcastGroup(payload, get(initialValues, "id"))
      : onCreateBrodcastGroup(payload);
  };

  return (
    <div className="flex flex-col h-full justify-start relative">
     {loading &&  <Spin spinning={loading} className="spinner-overlay" />}
      <Form
        className="flex flex-col h-full"
        form={form}
        name="login"
        layout="vertical"
        initialValues={
          edit
            ? {
                ...initialValues,
                type: get(initialValues, "type.value"),
              }
            : {}
        }
        onFinish={onFinish}
        onFinishFailed={() => {}}
      >
        <Row gutter={32}>
          <Col span={12}>
        <Form.Item
          name="name"
          label="Name"
          rules={[{ required: true, message: "Please input group name!" }]}
        >
          <Input size="large" placeholder="Name" />
        </Form.Item>
        </Col>
        <Col span={12}>
        <Form.Item
          name="type"
          label="Type"
          rules={[{ required: true, message: "Please input group name!" }]}
        >
          <Select
            size="large"
            className="flex w-full"
            onChange={(val) => {
              setType(val);
              setDisableValidate(false);
            }}
            disabled={edit}
            placeholder="Type"
            options={groupTypeOptions}
          />
        </Form.Item>
        </Col>
        </Row>
        <Form.Item
          name="enteries"
          label={
            <p>
              Enteries{" "}
              <span className="opacity-45">
                (comma seperated eg : a@gmail.com, b@gmail.com)
              </span>
            </p>
          }
          rules={[{ required: true, message: "Please input enteries!" }]}
          validateStatus={isError && disableValidate ? "error" : ""}
          help={
            isError && disableValidate ? "Some of the enteries are wrong" : null
          }
        >
          <TextArea
            value={enteries}
            placeholder="Enteries"
            autoSize={{ minRows: 2 }}
            onChange={(val) => {
              setEnteries(val.target.value);
              setDisableValidate(false);
            }}
          />
        </Form.Item>
        <div className="flex flex-col w-full justify-end items-end pb-5">
          <Button
            disabled={disableValidate}
            className="w-fit"
            type="primary"
            onClick={() => onValidateEnteries()}
          >
            Validate Enteries
          </Button>
        </div>
        <div className="mb-5">
          {map(validateEnteries, (item, index) => (
            <Tag
              className="m-1"
              key={get(item, "value")}
              color={get(item, "error") ? "red" : "geekblue"}
              closeIcon
              onClose={(val) => onRemoveTag(item, index)}
            >
              {get(item, "value")}
            </Tag>
          ))}
        </div>
        <div className="w-full flex flex-1 h-full items-end space-x-5 pb-5">
          <Button
            className="flex w-full"
            onClick={() => toggleBrodcastGroupForm()}
          >
            Cancel
          </Button>
          <Button
            type="primary"
            className="flex w-full"
            htmlType="submit"
            onClick={() => {}}
            disabled={isError || !disableValidate}
          >
            {edit ? "Update" : "Add"}
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default AddBrodcastGroup;
