import { useState } from "react";
import { useDispatch } from "react-redux";
import { Form, Row, Button, Steps, message, Col, Spin } from "antd";
import get from "lodash/get";
import map from "lodash/map";
import dayjs from "dayjs";
import capitalize from "lodash/capitalize";
import {
  createVehicle,
  updateVehicle,
} from "../../../redux/actions/vehiclesAction";
import {
  CREATE_VEHICLE_SUCCESS,
  UPDATE_VEHICLE_SUCCESS,
} from "../../../constants/message";
import VehicleBasicInfo from "./vehicleBasicInfo";
import VehicleEngineInfo from "./vehicleEngineInfo";
import VehicleImages from "./vehicleImages";
import { vehicleFormValidation } from "../../../utilities/vehicle";
import SafetyComforts from "./safetyComforts";
import InspectionChecks from "./inspectionChecks";
import InteriorExterior from "./interiorExterior";
import { vehicleFormTabs } from "../../../utilities/vehicle";
import { getVehicleInitialValues } from "../../../utilities/vehicle";

const AddVehicleForm = ({
  edit,
  initialValues,
  vehicleConfig,
  onSuccess,
  toggleVehicleForm,
}) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [formvalues, setFormValues] = useState({});
  const [current, setCurrent] = useState(0);
  const [thumbnailImage, setThumbnailImage] = useState(
    edit ? get(initialValues, "thumbnail_image") : { key: null, url: null }
  );
  const [images, setImages] = useState(
    edit ? get(initialValues, "images") : []
  );

  const onCreateVehicle = (payload) => {
    dispatch(createVehicle(payload))
      .then(() => {
        setLoading(false);
        message.success(CREATE_VEHICLE_SUCCESS);
        toggleVehicleForm();
        onSuccess();
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const onUpdateVehicle = (payload) => {
    dispatch(updateVehicle(payload, get(initialValues, "id")))
      .then(() => {
        setLoading(false);
        message.success(UPDATE_VEHICLE_SUCCESS);
        toggleVehicleForm();
        onSuccess();
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const onFinish = (values) => {
    const payload = {
      ...formvalues,
      ...values,
      color: capitalize(get(formvalues, "color")),
      date_of_registration: dayjs(
        get(formvalues, "date_of_registration")
      ).format("YYYY-MM-DD"),
      date_registration_exp: dayjs(
        get(formvalues, "date_registration_exp")
      ).format("YYYY-MM-DD"),
      make_year: dayjs(get(formvalues, "make_year")).format("YYYY"),
      thumbnail_image: get(values, "thumbnail_image.key"),
      images: map(images, (image) => get(image, "key")),
    };
    setLoading(true);
    edit ? onUpdateVehicle(payload) : onCreateVehicle(payload);
  };

  const onNext = async () => {
    if (current === vehicleFormTabs.length - 1) return;
    try {
      await form.validateFields(vehicleFormValidation[current]);
      setFormValues({
        ...formvalues,
        ...form.getFieldsValue(),
      });
      setCurrent(current + 1);
    } catch (error) {}
  };

  return (
    <>
      {loading && <Spin spinning={loading} className="spinner-overlay" />}
      <div className="flex flex-col h-full">
        <div className="flex flex-row justify-between items-center px-8 pt-5 ">
          <p className="text-black text-xl font-medium">
            {edit ? "Update Vehicle" : "Add Vehicle"}
          </p>
        </div>
        <div className=" flex px-8 pt-8 ">
          <Steps
            current={current}
            items={vehicleFormTabs}
            onChange={(val) => val < current && setCurrent(val)}
          />
        </div>
        <div className="pt-7">
          <div className="h-[1px] w-full bg-grey-4 " />
        </div>
        <div className="flex-grow flex-1 overflow-y-auto scrollbar-hidden px-8">
          <Form
            form={form}
            layout="vertical"
            scrollToFirstError
            onFinish={onFinish}
            onFinishFailed={() => {}}
            initialValues={edit ? getVehicleInitialValues(initialValues) : {}}
            className="flex flex-col overflow-y-auto"
          >
            <div className="mt-5 flex-1 flex flex-col">
              {current === 0 && (
                <VehicleBasicInfo form={form} vehicleConfig={vehicleConfig} />
              )}
              {current === 1 && (
                <VehicleEngineInfo vehicleConfig={vehicleConfig} />
              )}
              {current === 2 && (
                <InteriorExterior vehicleConfig={vehicleConfig} />
              )}
              {current === 3 && (
                <SafetyComforts vehicleConfig={vehicleConfig} />
              )}
              {current === 4 && (
                <InspectionChecks vehicleConfig={vehicleConfig} />
              )}
              {current === 5 && (
                <VehicleImages
                  form={form}
                  thumbnailImage={thumbnailImage}
                  setThumbnailImage={setThumbnailImage}
                  images={images}
                  setImages={setImages}
                  setLoading={setLoading}
                />
              )}
            </div>
          </Form>
        </div>
        <div className="flex  justify-end   border-t border-grey-4 over">
          <div className="px-8 py-3">
            <Row gutter={16}>
              <Col>
                <Button
                  onClick={() =>
                    current === 0
                      ? toggleVehicleForm()
                      : setCurrent(current - 1)
                  }
                >
                  {current === 0 ? "Cancel" : "Back"}
                </Button>
              </Col>

              {current !== vehicleFormTabs.length - 1 ? (
                <Col>
                  <Button
                    type="primary"
                    onClick={() => {
                      onNext();
                    }}
                  >
                    {"Next"}
                  </Button>
                </Col>
              ) : (
                <Col>
                  <Button block type="primary" onClick={() => form.submit()}>
                    {edit ? "Update" : "Save"}
                  </Button>
                </Col>
              )}
            </Row>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddVehicleForm;
