import { useEffect, useState } from "react";
import { Layout, Menu, Image, Button } from "antd";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import get from "lodash/get";
import { getAdminMenutems, getVendorMenutems } from "../../utilities/layout";
import logo from "../../static/images/logo.png";
import contactSupportImage from "../../static/images/contactSupport.svg";

const { Sider } = Layout;

const LayoutSider = () => {
  const location = useLocation();
  const user = useSelector((state) => get(state, "auth.user"));
  const [selectKey, setSelectedKey] = useState("");
  const navigate = useNavigate();
  const menuItems =
    get(user, "role.value") === 1
      ? getAdminMenutems(navigate, selectKey)
      : getVendorMenutems(navigate, selectKey);

  useEffect(() => {
    setSelectedKey(location.pathname);
  }, [location]);

  return (
    <Sider width={240} className=" relative flex flex-col w-full ">
      <div className="h-[73px] py-5 w-full justify-center  flex flex-col  px-5 border-grey-1 border-b border-r border-opacity-30 items-center ">
        <Image src={logo} alt="logo" width={120} preview={false} />
      </div>
      <div className="pt-14 px-4 flex-grow">
        <Menu
          style={{ textAlign: "left" }}
          mode="inline"
          inlineIndent={20}
          className="bg-transparent space-y-5"
          selectedKeys={[selectKey]}
          items={menuItems}
          onClick={(e) => navigate(e.key)}
        />
      </div>
      <div className="bottom-8 w-full flex absolute justify-end items-end px-5 group-hover-class">
        <Button
          type="primary"
          className="bg-[#5E6366] flex w-full h-[46px] rounded-2xl bg-opacity-10 border-none hover:border-primary"
          icon={
            <Image
              preview={false}
              src={contactSupportImage}
              alt="contact support "
              className="hover-primary"
            />
          }
          onClick={() => (window.location = "mailto:support@visao.io")}
        >
          <p className="text-black-3 hover-primary"> Contact Support</p>
        </Button>
      </div>
    </Sider>
  );
};

export default LayoutSider;
