import { Image, Tooltip, Space } from "antd";
import get from "lodash/get";
import { EditTwoTone, AlertTwoTone } from "@ant-design/icons";
import { getAddressString } from "../../utilities/helpers";
import ConfirmSwitch from "../../components/confirmSwitch";
import adminIcon from "../../static/icons/admins.svg";
import { COLOR_PRIMARY } from "../../constants";

export const vendorColumns = (
  toggleVendorAdminDrawer,
  toggleVendorDrawer,
  updateStatus,
  toggleSubscriptionModal
) => [
  {
    title: "Logo",
    dataIndex: "logo",
    key: "logo",
    responsive: ["sm"],
    render: (image) => <Image width={100} src={get(image, "url")} />,
  },
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    className: "drag-visible",
  },
  {
    title: "Email",
    dataIndex: "email",
    responsive: ["sm"],
    key: "email",
  },
  {
    title: "Address",
    dataIndex: "address",
    responsive: ["sm"],
    key: "address",
    render: (address) => getAddressString(address),
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    render: (status, record) => (
      <ConfirmSwitch
        msg="are you sure you want to deactivate the Vendor?"
        active={get(status, "value") === 1}
        onConfirm={(value) => updateStatus(get(record, "id"), value ? 1 : 0)}
        okText="Yes"
        cancelText="No"
      />
    ),
  },
  {
    title: "",
    key: "action",
    render: (record) => (
      <Space size={15}>
        <Tooltip title="Update Vendor">
          <EditTwoTone
            style={{ fontSize: 20 }}
            twoToneColor={COLOR_PRIMARY}
            onClick={() => {
              toggleVendorDrawer(record);
            }}
          />
        </Tooltip>
        <div className="hover:cursor-pointer">
          <Tooltip title="Administrators">
            <Image
              preview={false}
              src={adminIcon}
              alt="admins"
              onClick={() => toggleVendorAdminDrawer(record)}
            />
          </Tooltip>
        </div>
        <Tooltip title="View Subscriptions">
          <AlertTwoTone
            style={{ fontSize: 20 }}
            twoToneColor={COLOR_PRIMARY}
            onClick={() => {
              toggleSubscriptionModal(record);
            }}
          />
        </Tooltip>
      </Space>
    ),
  },
];
