import { Row, Form, Checkbox, List, Col } from "antd";
import get from "lodash/get";

const SafetyComforts = ({ vehicleConfig }) => {
  const safetyChecks = get(vehicleConfig, "safety_checks", []);
  const comfortCoonveniences = get(vehicleConfig, "comfort_conveniences", []);

  return (
    <Row className="flex h-full">
      <Col span={11} className="overflow-hidden">
        {" "}
        <Form.Item
          label={<p className="font-medium text-base">Safety</p>}
          name={"safety_checks"}
        >
          <Checkbox.Group className="flex flex-col  w-full space-y-3">
            <List
              dataSource={safetyChecks}
              renderItem={(item) => (
                <List.Item>
                  <Checkbox value={item.value} className="custom-checkbox">
                    {item.label}
                  </Checkbox>
                </List.Item>
              )}
              className=" max-h-[460px] 2xl:max-h-[620px] overflow-y-auto"
            />
          </Checkbox.Group>
        </Form.Item>
      </Col>
      <Col span={2} />
      <Col span={11} className="overflow-hidden">
        <Form.Item
          label={<p className="font-medium text-base">Comfort Conveniences</p>}
          name={"comfort_conveniences"}
        >
          <Checkbox.Group className="flex flex-col  w-screen space-y-3 h-full">
            <List
              dataSource={comfortCoonveniences}
              renderItem={(item) => (
                <List.Item>
                  <Checkbox value={item.value} className="custom-checkbox">
                    {item.label}
                  </Checkbox>
                </List.Item>
              )}
              className=" max-h-[460px] 2xl:max-h-[620px] overflow-y-auto"
            />
          </Checkbox.Group>
        </Form.Item>
      </Col>
    </Row>
  );
};

export default SafetyComforts;
