export const CHANGE_PASSWORD_SUCCESS = "Password updated Successfully";
export const CREATE_VENDOR_SUCCESS = "Vendor added successfully";
export const UPDATE_VENDOR_SUCCESS = "Vendor updated successfully";
export const CREATE_VENDOR_ADMIN_SUCCESS = "Vendor admin created successfully";
export const UPDATE_VENDOR_ADMIN_SUCCESS = "Vendor admin updated successfully";
export const CREATE_VEHICLE_SUCCESS = "Vehicle created successfully";
export const UPDATE_VEHICLE_SUCCESS = "Vehicle updated successfully";
export const CREATE_GROUP_SUCCESS = "Group created successfully";
export const UPDATE_GROUP_SUCCESS = "Group updated successfully";
export const DELETE_GROUP_SUCCESS = "Group deleted successfully";
export const BRODCAST_SUCCESS = "Message brodcast successfully";
export const UPDATE_LEAD_STATUS = "Lead status updated successfully";
export const UPDATE_VENDOR_STATUS = "Vendor status updated successfully";
export const UPDATE_VEHICLE_STATUS = "Status updated successfully";
export const UPDATE_VEHICLE_FEATURED = "Featured updated successfully";
export const DELETE_VEHICLE_SUCCESS = "Vehicle deleted successfully";
export const EMAIL_SEND_SUCCESS = "Please check your email to update password";
export const CREATE_SUBSCRIPTION_SUCCESS = "Subscription created successfully";
export const UPDATE_SUBSCRIPTION_SUCCESS = "Subscription updated successfully";
export const DELETE_SUBSCRIPTION_SUCCESS = "Subscription deleted successfully";
export const SUBSCRIPTION_SUBSCRIBE_SUCCESS =
  "Subscription has been successfully activated";
export const BRODCAST_MESSAGE_SUCCESS = "Message brodcast successfully";
