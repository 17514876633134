import {
  DASHBOARD,
  VENDROS,
  PROFILE,
  VENDRO_SETTINGS,
  VEHICLES,
  BROADCAST_GROUP,
  LEADS,
  SUBSCRIPTION,
  BROADCAST_LOG,
} from "../constants/routes";

import { Image } from "antd";
import dashboardIcon from "../static/icons/menu/dashboard.svg";
import personlisationIcon from "../static/icons/menu/personlisation.svg";
import vehoicleIcon from "../static/icons/menu/car.svg";
import leadsIcon from "../static/icons/menu/leads.svg";
import logsIcon from "../static/icons/menu/logs.svg";
import brodcastIcon from "../static/icons/menu/brodcast.svg";
import groupIcon from "../static/icons/menu/groupUsers.svg";
import subscriptionIcon from "../static/icons/menu/subscription.svg";

export const getAdminMenutems = (navigate, selectKey) => {
  return [
    // {
    //   key: DASHBOARD,
    //   icon: (
    //     <Image
    //       preview={false}
    //       src={dashboardIcon}
    //       alt="dashboard"
    //       className={`pt-3 pr-3 ${selectKey === DASHBOARD && "white-color"}`}
    //     />
    //   ),
    //   label: "Dashboard",
    //   onClick: () => navigate(DASHBOARD),
    // },
    {
      key: VENDROS,
      icon: (
        <Image
          preview={false}
          src={personlisationIcon}
          alt="vendors"
          className={`pt-3 pr-3 ${selectKey === VENDROS && "white-color"}`}
        />
      ),
      label: "Vendors",
      onClick: () => navigate(VENDROS),
    },
    {
      key: SUBSCRIPTION,
      icon: (
        <Image
          preview={false}
          src={subscriptionIcon}
          alt="Subscription"
          className={`pt-3 pr-3 ${selectKey === SUBSCRIPTION && "white-color"}`}
        />
      ),
      label: "Subscription",
      onClick: () => navigate(SUBSCRIPTION),
    },
  ];
};

export const getVendorMenutems = (navigate, selectKey) => {
  return [
    {
      key: DASHBOARD,
      icon: (
        <Image
          preview={false}
          src={dashboardIcon}
          alt="dashboard"
          className={`pt-3 pr-3 ${selectKey === DASHBOARD && "white-color"}`}
        />
      ),
      label: "Dashboard",
      onClick: () => navigate(DASHBOARD),
    },
    {
      key: VENDRO_SETTINGS,
      icon: (
        <Image
          preview={false}
          src={personlisationIcon}
          alt="personlisation"
          className={`pt-3 pr-3 ${
            selectKey === VENDRO_SETTINGS && "white-color"
          }`}
        />
      ),
      label: "Personalisations",
      onClick: () => navigate(VENDRO_SETTINGS),
    },
    {
      key: VEHICLES,
      icon: (
        <Image
          preview={false}
          src={vehoicleIcon}
          alt="vehicle"
          className={`pt-3 pr-3 ${selectKey === VEHICLES && "white-color"}`}
        />
      ),
      label: "Vehicles",
      onClick: () => navigate(VEHICLES),
    },
    {
      key: `${BROADCAST_GROUP}-menu`,
      icon: (
        <Image
          preview={false}
          src={brodcastIcon}
          alt="brodcast"
          className={`pt-3 pr-3 `}
        />
      ),
      label: <p className="text-black-1">Brodcasting</p>,

      children: [
        {
          key: BROADCAST_GROUP,
          icon: (
            <Image
              preview={false}
              src={groupIcon}
              alt="brodcast"
              className={`pt-3 pr-3 ${
                selectKey === BROADCAST_GROUP && "white-color"
              }`}
            />
          ),
          label: "Groups",
          onClick: () => navigate(BROADCAST_GROUP),
        },
        {
          key: BROADCAST_LOG,
          icon: <Image
          preview={false}
          src={logsIcon}
          alt="brodcast"
          className={`pt-3 pr-3 ${
            selectKey === BROADCAST_LOG && "white-color"
          }`}
        />,
          label: "Broadcast Log",
          onClick: () => navigate(BROADCAST_LOG),
        },
      ],
    },
    {
      key: LEADS,
      icon: (
        <Image
          preview={false}
          src={leadsIcon}
          alt="brodcast"
          className={`pt-3 pr-3 ${selectKey === LEADS && "white-color"}`}
        />
      ),
      label: "Leads",
      onClick: () => navigate(LEADS),
    },
  ];
};

export const getMenuItems = (navigate, onClickLogout) => [
  {
    key: "1",
    label: "Profile",
    onClick: () => navigate(PROFILE),
  },
  {
    key: "2",
    label: "Logout",
    onClick: () => onClickLogout(),
  },
];
