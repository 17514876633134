import { useState } from "react";
import { useDispatch } from "react-redux";
import {
  Descriptions,
  Badge,
  Divider,
  Row,
  Col,
  Spin,
  Drawer,
  Button,
} from "antd";
import { CalendarOutlined } from "@ant-design/icons";
import map from "lodash/map";
import get from "lodash/get";
import reverse from "lodash/reverse";
import dayjs from "dayjs";
import VehicleDetails from "../vehicleDetails";

import LeadStatusForm from "../forms/LeadStatusForm";
import {
  leadsDescription,
  leadsVehicleDescription,
} from "../../utilities/leads";
import { fetchLeadDetails } from "../../redux/actions/LeadsActions";
import { getLeadStatusBadgeColor } from "../../utilities/leads";
import { COLOR_PRIMARY } from "../../constants";

const LeadsDetails = ({ initialLead }) => {
  const dispatch = useDispatch();
  const [leadStatusModal, setLeadStatusModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [leads, setLeads] = useState(initialLead);
  const toggleLeadStatusForm = () => {
    setLeadStatusModal(!leadStatusModal);
  };
  const [vehicleDrawer, setVehicleDrawer] = useState(false);
  const toggleVehicleDrawer = () => {
    setVehicleDrawer(!vehicleDrawer);
  };

  const getLeadDetails = () => {
    setLoading(true);
    dispatch(fetchLeadDetails(get(leads, "id")))
      .then((result) => {
        setLeads(result);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  return (
    <div className="p-5 flex flex-col h-full">
      <Spin spinning={loading}>
        <div>
          <Row gutter={32}>
            <Col span={12}>
              <Descriptions
                layout="vertical"
                title="User Info"
                items={leadsDescription(leads)}
              />
              <div className="flex flex-col w-full my-5">
                <Badge.Ribbon text={"Query"} color="pink">
                  <div className="border border-grey-1 bg-[#fafafa] rounded-lg p-3  ">
                    <div className="flex flex-row space-x-3 mb-3">
                      <CalendarOutlined />
                      <p className="text-black text-xs opacity-60">
                        {get(leads, "created_at_formatted")}
                      </p>
                    </div>

                    <p className="text-black text-sm">{get(leads, "query")}</p>
                  </div>
                </Badge.Ribbon>
              </div>
            </Col>
            <Col span={1} />
            <Col span={11}>
              <Descriptions
                layout="vertical"
                title={
                  <div className="flex flex-row justify-between">
                    <p>Vehicle Info</p>
                    <Button
                      style={{ borderColor: COLOR_PRIMARY }}
                      onClick={() => toggleVehicleDrawer()}
                    >
                      <p className="text-primary">
                      Vehicle Details
                      </p>
                    </Button>
                  </div>
                }
                items={leadsVehicleDescription(get(leads, "vehicle"))}
              />
            </Col>
          </Row>
        </div>
        <Divider />
        
        <Row className="" gutter={32}>
          <Col span={12} className="flex flex-col space-y-5 mb-5">
            {map(
              reverse(get(leads, "followup_comments").slice()),
              (followup) => (
                <Badge.Ribbon
                  text={get(followup, "status.name")}
                  color={getLeadStatusBadgeColor(get(followup, "status.value"))}
                >
                  <div className="border border-grey-1 bg-[#fafafa] rounded-lg p-3 ">
                    <div className="flex flex-row space-x-3 mb-3">
                      <CalendarOutlined />
                      <p className="text-black text-xs opacity-60">
                        {dayjs(
                          get(followup, "date"),
                          "DD-MM-YYYY HH:mm:ss"
                        ).format("Do MMM, YYYY (hh:mm A)")}
                      </p>
                    </div>

                    <p className="text-black text-sm">
                      {get(followup, "comments")}
                    </p>
                  </div>
                </Badge.Ribbon>
              )
            )}
          </Col>
          <Col span={1} />
          <Col span={11} >
          {get(leads, "status.value") !== 3 && (
          <LeadStatusForm
            lead={leads}
            toggleLeadStatusForm={toggleLeadStatusForm}
            setLoading={setLoading}
            onSucess={() => getLeadDetails()}
          />
        )}

          </Col>
        </Row>
      </Spin>
      <Drawer
        title={"Vehicle Details"}
        destroyOnClose
        width={"80%"}
        onClose={() => toggleVehicleDrawer()}
        open={vehicleDrawer}
      >
        <VehicleDetails vehicle={get(leads, "vehicle")} />
      </Drawer>
    </div>
  );
};

export default LeadsDetails;
