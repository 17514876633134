import { Tooltip, Space, Tag, Image, Switch, Row } from "antd";
import get from "lodash/get";
import {
  EditTwoTone,
  EyeTwoTone,
  NotificationTwoTone,
  DeleteTwoTone,
} from "@ant-design/icons";
import { COLOR_PRIMARY } from "../../constants";
import { numberToWords } from "../../utilities/helpers";

const getFuelTagColor = (val) => {
  switch (val) {
    case 1:
      return "green";
    case 2:
      return "cyan";
    case 3:
      return "blue";
    default:
      return "purple";
  }
};

const getBodyTypeTagColor = (val) => {
  switch (val) {
    case 1:
      return "orange";
    case 2:
      return "gold";
    case 3:
      return "lime";
    default:
      return "purple";
  }
};

export const vehicleColumns = ({
  toggleVehicleForm,
  toggleVehicleDetails,
  toggleBrodcastModal,
  onMarkFeatured,
  toggleUpdateStatusModal,
  onClickDelete,
}) => [
  {
    title: "",
    dataIndex: "thumbnail_image",
    key: "thumbnail_image",
    responsive: ["sm"],
    render: (thumbnailImage) => (
      <Image width={50} src={get(thumbnailImage, "url")} />
    ),
  },
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    render: (name, record) => `${name} (${get(record, "model")})`,
  },
  {
    title: "Price",
    dataIndex: "price",
    key: "price",
    render: (price) => `${numberToWords(price)}`,
  },
  {
    title: "Fuel Type",
    dataIndex: "fuel_type",
    key: "fuel_type",
    render: (fuelType) => (
      <Tag color={getFuelTagColor(get(fuelType, "value"))}>
        {get(fuelType, "name")}
      </Tag>
    ),
  },
  {
    title: "Milage",
    dataIndex: "engine_config",
    key: "milage",
    render: (engineConfig) => `${get(engineConfig, "milage")} Km/Ltr`,
  },
  {
    title: "Body Type",
    dataIndex: "body_type",
    key: "body_type",
    render: (bodyType) => (
      <Tag color={getBodyTypeTagColor(get(bodyType, "value"))}>
        {get(bodyType, "name")}
      </Tag>
    ),
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    render: (status, record) => {
      return (
        <Space>
          <Row className=" min-w-14">
            <Tag color={getBodyTypeTagColor(get(status, "value"))}>
              {get(status, "name")}
            </Tag>
          </Row>
          <Row>
            <Tooltip title="Update Status">
              <EditTwoTone
                style={{ fontSize: 20 }}
                twoToneColor={COLOR_PRIMARY}
                onClick={() => {
                  toggleUpdateStatusModal(record);
                }}
              />
            </Tooltip>
          </Row>
        </Space>
      );
    },
  },
  {
    title: "Featured",
    dataIndex: "is_featured",
    key: "is_featured",
    render: (featured, record) => (
      <Switch
        onClick={() =>
          featured
            ? onMarkFeatured(get(record, "id"), false)
            : onMarkFeatured(get(record, "id"), true)
        }
        checked={featured}
      />
    ),
  },
  {
    title: "",
    key: "action",
    align: "center",
    render: (record) => (
      <Space size={15}>
        {get(record, "status.value") === 0 && (
          <Tooltip title="Brodcast Message">
            <NotificationTwoTone
              style={{ fontSize: 20 }}
              twoToneColor={COLOR_PRIMARY}
              onClick={() => {
                toggleBrodcastModal(record);
              }}
            />
          </Tooltip>
        )}
        <Tooltip title="Edit Vehicle">
          <EditTwoTone
            style={{ fontSize: 20 }}
            twoToneColor={COLOR_PRIMARY}
            onClick={() => {
              toggleVehicleForm(record);
            }}
          />
        </Tooltip>
        <Tooltip title="View Full Details">
          <EyeTwoTone
            style={{ fontSize: 20 }}
            twoToneColor={COLOR_PRIMARY}
            onClick={() => {
              toggleVehicleDetails(record);
            }}
          />
        </Tooltip>
        <Tooltip title="Delete Vehicle">
          <DeleteTwoTone
            style={{ fontSize: 20 }}
            twoToneColor={COLOR_PRIMARY}
            onClick={() => {
              onClickDelete(get(record, "id"));
            }}
          />
        </Tooltip>
      </Space>
    ),
  },
];
